
@charset "UTF-8";

$px:0.27vw;
$m:20*$px;


/* !!------------------------------------ */
/* !! 共通 */

html {
	font-size: 62.5%;
	line-height: 1.6; 
}

body{
	margin: 0;
	padding: 0;
	color: $text;
	padding-top: 70px;
	@include gothic;
	font-feature-settings: 'palt' 1;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
}

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section , address {
	display: block;
	overflow: hidden;
}

a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
	
	&:hover {
		opacity: 0.7;
	}
	
}

ul , ol {
	list-style: none;
	font-size: 0;
	line-height: 1;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure , form , button {
	margin: 0;
	padding: 0;
	letter-spacing: 0;
	font-weight: normal;
}

i, cite, em, var, address, dfn {
	font-style: normal;	
}

img {
	vertical-align:bottom;
	backface-visibility: hidden;
	outline:none;
	border: 0;
	-ms-interpolation-mode: bicubic;
}

p {
	margin: 0;
	overflow: hidden;
}

form {
	margin: 0;
}

::selection {
	color: #006934;
	background: #cadb4d;
}

::-moz-selection {
	color: #006934;
	background: #cadb4d;
}

#container {
	width: 100%;
	margin: 0 auto;
	position:relative;
}

/*
.inner {
	margin:0 auto;
	width: 100%;
	max-width: 1200px;
	height: 100%;
	position:relative;
	overflow: hidden;
}
*/

.pc {
	display: none !important;
}

.SP {
	display: block !important;
}

ul.disk{
	list-style: disc;
	padding-left: 1.4em;
}

select,input[type=text],input[type=email]{
	appearance: none;
	display: block;
	height: 100%;
	width: 100%;
	box-shadow: none;
	border: none;
	padding: 10px;
	font-size: 1.5rem;
	&:focus{
		outline: none;
	}
}



/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! SP */
/* !!------------------------------------ */
/* !!------------------------------------ */



/* !!------------------------------------ */
/* !! ヘッダー */
#site-header{
	height: 70px;
	position: relative;
	position: fixed;
	z-index: 10;
	top: 0;left: 0;
	width: 100%;
	background: #fff;
	#logo{
		position: absolute;
		left: 10px;top: 50%;
		@include transform(translateY(-50%));
		img{
			height: 30px;
			width: auto;
		}
	}
	
	
	
	#btn-spmenu{
		position: absolute;
		top: 10px;right: 15px;
		z-index: 2;
		cursor: pointer;
	}
}


a.more{	
	display: block;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
	position: relative;
	&:before{
		content: "もっと見る..."
	}
	&:after{
		display: block;
		height: 100px;
		position: absolute;
		top: 0;left: 0;
		width: 100%;
		content: "";
		background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 255, 255, 1) 10%,rgba(255, 255, 255, 0) 100%);
		background : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
		background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0.1,rgba(255, 255, 255, 1) ),color-stop(1,rgba(255, 255, 255, 0) ));
		background : -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
		background : -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#FFFFFF' ,GradientType=0)";
		background : linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#FFFFFF' , GradientType=0);
		@include transform(translateY(-100%));
	}
}



/* !!------------------------------------ */
/* !! グローバルナビ */

nav#gnav{
	width: 100%;
	min-width: 0;
	position: fixed;
	top: 0px;
	height: 100vh;
	left: 0;
	z-index: 11;
	background: #fff;
	transition: 600ms opacity, 100ms height;
	opacity: 0;
	pointer-events: none;
	@include transform(translateY(0));
	
	body.menu_on &{
		@include transform(translateY(0));
		opacity: 1;
		pointer-events: auto;
	}
	
	ul{
		padding:0;
		display: list-item;
		width: 100%;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: 0 auto;
		position: relative;
		top: 50%;
		margin-top: -50px;
		z-index: 1;
		@include transform(translateY(-50%));
		li{
			width: auto;
			height: auto;padding-left: 0px;
			width: 100%;
			text-align: center;
			&:last-of-type a{
				border-right: none;
			}
			a{
				display: block;
				//background: #eee;
				line-height: 40px;
				font-size: 14px;
				margin: 0;
				padding: 0;
				//background: #eee;
				display: block;
				color: $green;
				position: relative;
			}
			
		}
	}
}


.btn-partner {
	background: $green;
	height: 100px;width: 100%;
	position: absolute;
	bottom: 0;left: 0;
	&:before{
		content: "";
		width: 100%;
		height: 50px;
		display: block;
		position: absolute;
		top: -46px;
		left: 0;
		@include bg-img(url(../img/spmenu-bg@2x.png),auto 100%,center bottom,repeat-x); //$url,$size,$position,$repeat
	}
	&:after{
		content: "";
		width: 100%;
		height: 100%;
		background: $green;
		z-index: 1;
		position: relative;
		display: block;
	}
	a{
		width: 275px;
		height: 40px;
		@include centering;
		text-align: center;
		color: #fff;
		line-height: 40px;
		font-size: 14px;
		display: block;
		border-radius: 4px;
		border: solid 1px #ffffff;
		background: $green;
		z-index: 2;
	}
}

.btn.close{
	@include tap;
	//transition: 300ms;
	top: 25px;
	right: 25px;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 10px;right: 15px;
	cursor: pointer;
	z-index: 2;
	@include bg-img(url(../img/btn-close@2x.png),30px 30px,center center); //$url,$size,$position,$repeat
}


/* !!------------------------------------ */
/* !! トップページ */

h2.dot{
	font-size: 22px;
	line-height: 40px;
	text-align: center;
	position: relative;
	color: $green2;
	margin: 0 30*$px;
	font-weight: 600;
	margin-bottom: 10px;
	&:before{
		content: "";
		display: block;
		height: 4px;
		@include bg-img(url(../../img/top/bg-dot1@3x.png),auto 4px); //$url,$size,$position,$repeat
	}
	&:after{
		content: "";
		display: block;
		height: 4px;
		@include bg-img(url(../../img/top/bg-dot1@3x.png),auto 4px); //$url,$size,$position,$repeat
	}
}

.dv{
	font-size: 24px;
	font-weight: 700;
	color: $green;
	text-align: center;
	line-height: 1;
	margin: 50px 0;
	letter-spacing: .08em;
	&:after{
		content: "";
		width: 50px;
		height: 3px;
		background: $green;
		display: block;
		margin: 0 auto;
		margin-top: 15px;
	}
}


.contact-box{
	padding: 30*$px 0;
	padding-top: 14*$px;
	a{
		margin-top: 10px;
		display: block;
	}
	img{
		width: 100% !important;
		height: auto !important;
	}

}



/* !! - - -  */
/* !! MV */
#MV{
	width: 100%;
	height: calc(100vh - 70px);
	position: relative;
	overflow: hidden;
	max-height: 760px;
	h1{
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		margin-top: -118*$px;
		z-index: 1;
		img{
			width: 100%;
			height: auto;
		}
	}
	.bnr-form{
		position: absolute;
		width: 100%;
		bottom: 30*$px;
		left: 0;
		z-index: 1;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	.cell{
		height: calc(100vh - 70px);
		width: 100vw;
		max-height: 760px;

		&._1{
			@include bg-img(url(../../img/top/SLD1@2x.jpg),cover); //$url,$size,$position,$repeat
		}
		&._2{
			@include bg-img(url(../../img/top/SLD2@2x.jpg),cover); //$url,$size,$position,$repeat
		}
		&._3{
			@include bg-img(url(../../img/top/SLD3@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}
/*
	#slide{
		font-size: 0;
		white-space: nowrap;
		text-align: center;
		opacity: 1;
		transition: 500ms opacity;
		transition-delay: 1000ms;
		&.set{
			opacity: 0;
		}
		.cell{
			display: inline-block;
		}
		
	}
*/
	
}

.bnr-call{
	a{
		display: block;
		margin: 30*$px 0;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
}

/* !! - - -  */
/* !! 比較するから価格も安い！ */
#estimate{
	
	h2{
		//@include MB;
		font-size: 24px;
		font-weight: 800;
		text-align: center;
		color: $red;
	}
	
	.estimate-slide{
		width: 100vw;
		white-space: nowrap;
		.cell{
			width:100vw ;
			display: inline-block;
			vertical-align: top;
			//background: #eee;
			padding: 0 30*$px;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			z-index: 1;
			justify-content: space-between;
			&:focus{
				outline:none;
			}
			&:before{
				content: "";
				width: 32*$px;
				margin-left: -15*$px;
				height: 67*$px;
				position: absolute;
				top: 38*$px;
				left: 50%;
				z-index: 2;
				@include bg-img(url(../../img/top/dec-tri@3x.png)); //$url,$size,$position,$repeat
			}
		}
		.img{
			width: calc((100% - 15px) / 2 );
			height: 130*$px;
			margin-top: 10px;
			background: #fff;
			position: relative;
			overflow: hidden;
			background: #efefef;
			img{
				width: 100% !important;
				height: auto !important;
			}
			&:before{
				content: "BEFORE";
				position: absolute;
				left: -11px;
				padding-left: 12px;
				font-size: 10px;
				top: 0px;
				background: #666666;
				width: 106px;
				text-align: left;
				transform-origin: center center;
				color: #fff;
				line-height: 15px;
				@include transform(rotate(-30deg));
			}
		}
		.after{
			&:before{
				content: "AFTER";
				background: $red;
			}
		}
		.price{
			width: calc((100% - 20px) / 3);
			border: 1px solid #BFBFBF;
			border-radius: 4px;
			overflow: hidden;
			font-size: 28px;
			text-align: center;
			@include MB;
			background: #fff;
			margin-top: 10px;
			letter-spacing: -.03em;
			position: relative;
			&.lowest{
				color: $red;
			}
			&:after{
				content: "万円";
				font-size: 12px;
				padding-left: 5px;
				color: #000;
			}
			i{
				background: #BFBFBF;
				font-size: 13px;
				line-height: 25px;
				text-align: center;
				color: #000;
				letter-spacing: 0;
				display: block;
			}
		}
		.result{
			width: 100%;
			line-height: 45px;
			font-size: 18px;
			white-space: nowrap;
			text-align: center;
			@include MB;
			color: #fff;
			border-radius: 4px;
			margin-top: 10px;
			@include bg-img(url(../../img/top/estimate-bg@3x.png),auto 100%); //$url,$size,$position,$repeat
			i{
				font-size: 38*$px;
				line-height: 45*$px;
				vertical-align: top;
				
				margin-top: 10px;
				color: #fff;
				&:after{
					content: "万円";
					font-size: 15*$px;
				}
			}
		}
	}
	
}//#estimate

/* !! - - -  */
/* !! リクラシとは */


#about{
	display: block;
	padding: 30*$px 0;
	padding-bottom: 0;
	.lead{
		text-align: center;
		font-size: 15px;
		line-height: 1.6;
		font-emphasize-position: before;
		color: $green2;
		font-weight: 600;
	}
	.img{
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	p.content{
		padding: 30*$px;
		padding-top: 20*$px;
		line-height: 1.5;
		@include grade1;
		font-size: 14px;
	}
}



#support{
	display: block;
	padding: 30*$px 0;
	h2{
		text-align: center;
		font-weight: bold;
		color: $green2;
		line-height: 1;
		margin-bottom: 20*$px;
		strong{
			font-size: 25px;
			display: block;
			margin: 10px 0;
		}
	}
	img{
		width: 100% !important;
		height: auto !important;
	}
	p{
		font-size: 14px;
		font-weight: bold;
		line-height: 1.29;
		text-align: center;
		color: $green2;
		padding: 30*$px;
		@include grade1;
	}
	.inner{
		margin:0 30*$px;
		.p1{
			text-align: justify;
			background: #fff;
			font-size: 14px;
			color: #000;
			padding: 10px;
			margin: 20px;
			margin-bottom: 0;
			margin-top: -55px;
			position: relative;
			z-index: 1;
			font-weight: normal;
			line-height: 1.5;
		}
		.p2{
			padding: 20*$px;
			position: relative;
			text-align: justify;
			color: #000;
			font-size: 14px;
			font-weight: normal;
			padding-right: 120*$px;
			overflow: visible;
			strong{
				display: block;
				font-size: 21px;
				@include MB;
				color: $green2;
				margin-bottom: 10px;
				white-space: nowrap;
			}
			img{
				position: absolute;
				bottom: 0;right: 0;
				width: 120*$px !important;
				height: auto !important;
				margin-right: -20*$px;
				z-index: 2;
			}
		}
	}
}

/* !! - - -  */
/* !! コンタクト */
#shade{
	position: fixed;
	background: rgba(0, 0, 0, 0.58);
	width: 100vw;
	height: 130vh;
	top: 0;left: 0;z-index: 11;
	opacity: 0;
	pointer-events: none;
	body.form_on &{
		opacity: 1;
		pointer-events: auto;
	}
}

#contact-wrap{
	pointer-events: none;
	padding:0 $m;
	bottom: 0;left: 0;
	height: 1px;
	width: 100%;
	z-index: 10;
	position: absolute;
	top: 0;left: 0;
	z-index: 12;
	overflow: visible;
	@include transform(translateY(20px));
	opacity: 0;
	pointer-events: none;
	transition: 300ms;
	body.form_on &{
		@include transform(translateY(0));
		opacity: 1;
		pointer-events: auto;
		.form{
			pointer-events: auto;
		}
	}
	.close{
		cursor: pointer;
		font-size: 0;
		position: absolute;
		top: 0;right: 0;
		z-index: 1;
		width: 36px;height: 36px;
		@include bg-img(url(../img/btn-close@2x.png),20px); //$url,$size,$position,$repeat
	}
	.form{
		
		pointer-events: none;
		position: absolute;
		width: calc(100% - 40px);
		box-sizing: border-box;
		top: 20px;left: 20px;
		max-width: 900px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.33);
		padding: $m;
		margin-top: 0*$px;
		//position: relative;
		z-index: 10;
		background: #fff;
		.idx{
			display: block;
			//justify-content: space-between;
			.ph{
				margin-bottom: 1*$px;
				text-align: left;
			}
			>div{
				width: 100%;
				margin-bottom: 20*$px;
				>div{
					padding-left: 50px;
					height: 50px;
					position: relative;
					&.select:before{
						@include anchor(8px);
						margin-top: -2px;
						select{
							appearance: none !important;
						}
					}
					i{
						height: 50px;
						width: 50px;
						position: absolute;
						top: 0;left: 0;
						background: $green;
						display: block;
						font-size: 1.6rem;
						color: #FFF;
						line-height: 50px;
						text-align: center;
						font-weight: bold;
					}
					&:after{
						content: "";
						width:100%;
						height:100%;
						position: absolute;
						border: 1px solid $line;
						top: 0;left: 0;z-index: 1;
						pointer-events: none;
					}
				}
			}
			
		}
		.ctx1{
			padding-left: 0 !important;
			margin-top: -15px;
			font-size: 1.2rem;
			line-height: 1;
		}
		
		button.btn1{
			background: #116736;
			color: #fff;
			font-size: 2.4rem;
			font-weight: bold;
			border: 2px solid #fff !important;
			border-radius: 10px;
			box-shadow: none;
			max-width: 600px;
			width: 100%;
			line-height: 70px;
			display: block;
			box-shadow: 0 5px 8px rgba(0, 0, 0, 0.33);
			margin: 30px auto;
		}
		
		.tel{
			margin-bottom: 30px;
			a{
				display: block;
				img{
					width: 100%;
					max-width: 700px;
					height: auto;
					display: block;
					margin: 0 auto;
				}
			}
		}
		.supp{
			background: #EFEEED;
			padding: 10px 15px;
			position: relative;
			font-size: 1.2rem !important;
			font-size: 1.57em;
			text-align: justify;
			i{
				float: none;
				display: block;
				text-align: right;
			}
		}
	}
	
}




/* !! - - -  */
/* !! リフォーム */
#reform{
	img{
		width: 100% !important;
		height: auto !important;
	}
	
	h2{
		text-align: center;
		padding:0 30*$px;
		color: $green;
		font-size: 18px;
		font-weight: bold;
		margin-top: 10*$px;
		margin-bottom: 15*$px;
		i{
			display: inline-block;
		}
	}
	
	p{
		padding:0 30*$px;
		margin-top: 10*$px;
		font-size: 14px;
	}
}



/* !! - - -  */
/* !! こんな方におすすめ */

#recommend{
	padding: 0;
	margin-bottom: 0px;
	padding-top: 30*$px;

	ul{
		max-width: 1200px;
		width: auto;
		margin: 20*$px 30*$px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			text-align: center;
			width: calc(50% - 5px);
			margin-bottom: 20px;
			.img{
				width: 100% !important;
				height: auto !important;
				img{
					width: 100% !important;
					height: auto !important;
					display: block;
				}
			}
			span{
				font-size: 12*$px;
				line-height: 1.5;
				white-space: nowrap;
				display: block;
				margin-top: 10*$px;
				text-align: left;
			}
		}
	}
	
}//#recommend




/* !! - - -  */
/* !! 見積り比較サイト【リクラシ】が選ばれている理由 */

section#why-rekurasi{
	padding-top: 30*$px;
	margin-bottom: 0;
	.inner{
		padding: 0;
		ul{
			display:block;
			//justify-content: center;
			li{
				margin-top: 20*$px;
				margin-right: 75*$px;
				&:nth-of-type(2n){
					margin-left: 75*$px;
					margin-right: 0;
					p{
						margin-left: 0;
					}
				}
				.hd{
					display: block;
					height: 150px;
					width: 100%;
					background: #eee;
					position: relative;
					margin-bottom: 10px;
					margin-left: 0;
					&._1{
						@include bg-img(url(../../img/top/p3@2x.jpg),cover); //$url,$size,$position,$repeat
					}
					&._2{
						@include bg-img(url(../../img/top/p4@2x.jpg),cover); //$url,$size,$position,$repeat
					}
					&._3{
						@include bg-img(url(../../img/top/p5@2x.jpg),cover); //$url,$size,$position,$repeat
					}
					strong{
						padding-left: 30*$px;
						background: rgba($green,.7);
						font-size: 16*$px;
						color: #fff;
						position: absolute;
						bottom: 10px;
						width: 100%;
						left: 0;
						z-index: 1;
						padding: 10px;
						line-height: 1.5;
						@include pfix(.5em);
					}
				}
				p{
					font-size: 12px;
					color: #000;
					margin-left: 30*$px;
					line-height: 1.4;
				}
				.note{
					@include note;
				}
			}
		}
	}
	
}





/* !! - - -  */
/* !! 施工実績 */

#reform-modelcase{
	padding-top: 50*$px;
	&.fold {
		ul.case-list{
			height: 300px;
		}
	}
	&:not(.fold){
		a.more:after{
			content: none;
		}
		a.more:before{
			content: "閉じる";
		}
	}
	ul.case-list{
		margin: 0 30*$px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 20*$px;
		overflow: hidden;
		transition: 500ms;
		li{
			display: block;
			width: calc((100% - 5px)/2);
			margin-bottom: 5px;
			img{
				width: 100% !important;
				height: auto !important;
			}
			a{
				display: block;
				white-space: nowrap;
				line-height: 32px;
				//text-align: center;
				//color: #FFF;
				font-size: 12px !important;
				//font-weight: bold;
			}
		}
	}
}






/* !! - - -  */
/* !! プロが語るリフォーム成功のポイント */


//!! - カテゴリ
#point{
	padding-top: 50*$px;
	ul.article-tag-list{
		border-bottom: 1px dotted $line;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		width: auto !important;
		padding-bottom: $m;
		margin: $m !important;
		font-size: 0;
		line-height: 1;
		li{
			flex:none;
			background: #fff;
			margin-right: auto;
			margin-bottom: 5px;
			height: 40px;
			display: block;
			width: calc((100% - 8px)/2) !important;
			a{
				display: block;
				line-height: 40px;
				font-size: 12px;
				padding-left: 30px;
				@include bg-img(url(../../img/icon-doc.png), 18px 21px, 10px 8px); //$url,$size,$position,$repeat
			}
		}
	}
}

#point:not(.fold){
	a.more:after{
		content: none;
	}
	a.more:before{
		content: "閉じる";
	}
}


//!! - 記事リスト
ul.article-list{
	width: 100%;
	margin-top: 20*$px;
	padding:0 30*$px;
	//margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: hidden;
	.fold & {
			height: 300px;
	}
	li{
		margin-top:0;
		margin-bottom:$m;
		width: calc((100% - 15px)/2);
		text-align: right;
		&:nth-of-type(5n + 1){
			width: 100%;
			.thumb{
				height: 150*$px;
				overflow: hidden;
				img{
					@include centering;
				}
			}
		}

		.thumb{
			position: relative;
			.date{
				display: none;
				background: #3E3A39;
				color: #d3edfb;
				position: absolute;
				top: 0;left: 0;
				z-index: 1;
				font-size: 12px;
				line-height: 24px;
				padding:0px 10px;
			}
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.title{
			font-size: 16px;
			text-align: justify;
			padding: 10px 0;
			@include pfix(.5em);
			line-height: 1.5;
			font-weight: bold;
		}
		.desc{
			font-size: 12px;
			text-align: justify;
			padding: 0;
			line-height: 1.6;
		}
		
		.btn-more{
			font-size: 14px !important;
			display: inline-block;
			border-bottom: 2px solid $text;
			padding-bottom: 4px;
			display: none;
			&:after{
				content: " ＞";
			}
		}
	}
}


/* !! - - -  */
/* !! ピックアップ加盟店 */
#partner{
	h3{
		background:rgba($green,0.6);
		line-height: 30px;
		text-align: center;
		font-size: 16px;
		letter-spacing: .1em;
		color: #FFF;
		font-weight: bold;
		margin: 0 30*$px;
		border-radius: 15px;
		& + ul{
			margin-top: 15*$px !important;
		}
	}
}

/* !! - - -  */
/* !! 「リクラシ」は第三者機関として、まずリフォーム */

#our-thought{
	background: $green;
	text-align: center;
	.inner{
		padding: 30*$px;
	}
	h2{
		background: none;
		color: #fff;
		font-size: 17px;
		font-weight: bold;
		margin-bottom: 26*$px;
		text-align: justify;
		br{
			display: none;
		}
	}
	p{
		strong{
			font-size: 14px;
		}
		text-align: justify;
		font-size:12px;
		color: #fff;
	}
	
}


/* !!------------------------------------ */
/* !! 施工事例 */
#works.index{
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
}

body.works{
	padding-bottom: 50px;
}//body.works


.notfound{
	text-align: center;
	font-size: 14px;
	border: 1px dashed #ccc;
	padding: 50px;
	margin: 50px 30*$px;
	max-width: 800px;
}

#works-index{
	
	font-size: 16px;
	margin: 30*$px;
	li{
		margin-top: 30px;
		position: relative;
		a{
			@include a-cover;
		}
		.img{
			height: 200*$px;
			background: #efefef;
			overflow: hidden;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.txt{
			margin-top: 10px;
			position: relative;
		}
		.cate{
			padding-left: 30px;
			padding-right: 30px;
			color: #fff;
			font-size: 13px;
			line-height: 30px;
			max-width: calc(100% - #{40*$px});
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-left: -30*$px;
			width: auto;
			display: inline-block;
			font-weight: bold;
			@include bg-img(url(../img/cate-label-bg@3x.png),auto 100%,top right); 
			i{
				padding-right: 10px;
			}
		}
		.date{
			font-size: 12px;
			line-height: 30px;
			position: absolute;
			top: 0;right: 0;
		}
		.desc{
			margin-top: 15px;
			line-height: 1.71;
			font-size: 14px;
		}
	}
	
}//#works-index



//!! 絞り込み
.category-filter-wrap{
	background: $green;	
	text-align: center;
	position:fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	//transform:translateY(calc(100% - 50px));
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	&.opened{
		.case-cate-list{
			transform:translateY(0) !important ;
		}
		
		i:before{
			@include transform(translateY(-50%) rotate(180deg));
		}
	}
	&:after{
		height: 50px;
		width: 100%;
		background: $green;
		content: "";
		display: block;
		bottom: 0;left: 0;
		position: absolute;
		z-index: 2;
	}
	i{
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
		cursor: pointer;
		color: #fff;
		line-height: 50px;
		padding-left: 40px;
		position: relative;
		z-index: 3;
		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%) rotate(0));
			display: inline-block;
			width: 32px;height: 32px;
			@include bg-img(url(../img/anchor1@2x.png)); //$url,$size,$position,$repeat
		}
	}
	
	.case-cate-list{
		font-size: 14px;
		padding: 0;
		position: absolute;
		bottom: 50px;
		background: #fff;
		width: 100%;
		height: calc(100vh - 50px);
		overflow: auto;
		transform:translateY(calc(120%));
		transition: 800ms $ease1;
		z-index: 1;
		li{
			color: $green;
			margin:0;
			flex-grow: 3;
			text-align: left;
			padding: 0 30*$px;
			a{
				display: block;
				font-size: 16px;
				padding: 0 12px;
				line-height: 58px;
				white-space: nowrap;
				border-bottom: 1px dotted $green;
			}
		}
	}
}


/* !! ページャー */

.wp-pagenavi{
	margin: 0 30*$px;
	text-align: center;
	padding: 0 30*$px;
	position: relative;
	a,span{
		width: 36px;
		height: 36px;
		background: #e5e5e5;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		line-height: 36px;
		margin: 0 2px;
		&.current{
			background: $green;
			color: #fff;
		}
	}
	.pages{
		display: none;
	}
	.previouspostslink{
		position: absolute;
		top: 0;left: 0;
		font-size: 0;
		width: 36px;height: 36px;
		background: none;
		float: left;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
	}
	.nextpostslink{
		position: absolute;
		top: 0;right: 0;
		font-size: 0;
		float: right;
		background: none;
		width: 36px;height: 36px;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
		transform: rotate(180deg);
	}
	.last,.first{
		display: none;
	}
}



/* !! - - -  */
/* !! single */
#works.single{
	
	.dv{
		text-align: center;
	}
	h1{
		text-align: center;
		border:1px solid $line;
		letter-spacing: .1em;
		max-width: 1000px;
		margin: 0 20*$px;
		border-width: 1px 0;
		line-height: 1;
		padding: 20px 0;
		font-size: 20px;
		i{
			font-size: .66em;
			font-weight: normal;
		}
	}
	
	#before-after{
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
		padding: 20*$px;
		&:after{
			content: "▼";
			font-size: 20px;
			color: $green;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));			
		}
		>div{
			width: 100%;
			height: auto;
			position: relative;
			& + div{
				margin-top: 50px;
			}
			img{
				width: 100% !important;
				height: auto !important;
			}
			.label{
				opacity: 1;
				transition: 300ms;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;background: $gold;
				line-height: 32px;
				padding: 0 20px;
				font-weight: bold;
				font-size: 15px;
				color: #fff;
				letter-spacing: .1em;
			}
		}
		.after{
			background: #efefef;
			transition: 500ms ;
			@include transform(translate(0,0));
			.photo{
				opacity: 1;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					top: 0;left: 0;
					z-index: 3;
					background: #fff;
					width:100%;
					height:100%;
					opacity: 0;
					transition: 3000ms;
					transition-delay: 200ms;				
				}
			}
			&.inv{
				@include transform(translate(0,50px));
				.label{
					opacity: 0;
				}
				.photo{
					opacity: 0;
					&:after{
						opacity: 1;					
					}
				}
			}
		}
	}
	
	.txt{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 0px;
		background: #f6f6f6;
		padding: 15px;
		border-radius: 8px;
		.cate{
			position: relative;
			padding-left: 110px;
			i{
				line-height: 30px;
				padding: 0 15px;
				background: #fff !important;
				border-radius: 15px;
				display: inline-block;
				margin-right: 10px;
				font-size: 12px;
			}
			span{
				position: absolute;
				left: 0;top: 0;
				line-height: 30px;
				width: 100px;
				background: $gold;
				color: #fff;
				text-align: center;
				font-size: 1.2rem;
				font-weight: bold;
			}
		}
		.desc{
			margin-top: 10px;
			font-size: 16px;
			line-height: 1.66;
			padding-top: 15px;
			border-top: 1px dotted #fff;
		}
	}
	
	.prev-next{
		max-width: 1000px;
		margin: 20px 30*$px;
		height: 30px;
		a{
			line-height: 30px;
			display: inline-block;
			color: $green;
			font-size: 14px;
			letter-spacing: .1em;
			//border-bottom: 1px solid $green !important;
		}
		.prev{
			&:before{
				content:"◀︎ ";
			}
		}
		.next{
			float: right;
			&:after{
				content:" ▶︎";
			}
		}
	}
	
}//#works.single


/* !!------------------------------------ */
/* !! 加盟店一覧 */
#partners.index{
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
}

#partners-index{
	
	font-size: 16px;
	margin: 30*$px;
	li{
		margin-top: 30px;
		.img{
			height: 200*$px;
			background: #efefef;
		}
		.txt{
			margin-top: 10px;
			position: relative;
			font-size: 14px;
			line-height: 1.6;
		}
		.name{
			font-weight: bold;
			letter-spacing: .14em;
		}
		.tel{
			position: relative;
			padding-left: 100px;
			font-size: 20px;
			i{
				position: absolute;
				top: 50%;left: 0;
				@include transform(translateY(-50%));
				width: 95px;
				text-align: center;
				background: #eee;
				font-size: 12px;
				line-height: 20px;
				color: #666;
			}
		}
	}
	
}//#partners-index

/* !!------------------------------------ */
/* !! アーカイブ */

main#topics{
	padding-bottom: 50*$px;
	border-bottom: none !important;
	.article-list{
		margin-top: 0;
		img{
			height: 100% !important;
			width: auto !important;
		}
		li:nth-of-type(5n + 1){
			
			.thumb{
				@include fitW(0);
				height: 100% !important;
				overflow: hidden;
				img{
					height: 100% !important;
					width: auto !important;
				}
			}
		}
		
	}
	@media screen and (max-width: 767px) {
		.article-list{
			margin-top: 0;
			img{
				height:auto !important;
				width: 100% !important;
				position: static;
				@include transform(translate(0,0));
			}
			li:nth-of-type(5n + 1){
				
				.thumb{
					@include fitW(0);
					height: auto !important;
					width: 100% !important;
					img{
						height: auto !important;
						width: 100% !important;
					}
				}
			}
			
		}
		
	}

}

/* !! - - -  */
/* !! カテゴリアーカイブ */
main.category-archive{
	
	h3{
		text-align: center;
		font-size: 2.0rem;
		@include transform(translateY(0));
		margin-bottom: 20px;
		span{
			display: inline-block;
			background: #EEECEB;
			padding: 0 20px;
		}
	}
	.notfound{
		display: block;
		background: rgba(255, 255, 255, 0.7);
		max-width: 1000px;
		
		padding:50px 20px !important;
		font-size: 1.6em;
		text-align: center;
		margin: 20*$px;
		border-radius: 10px;
		
	}

}





/* !!------------------------------------ */
/* !! お役立ち情報シングル */

main.single{

	>section{
		padding:  30*$px;
		padding-top: 10*$px;
	}	
	
	.mv{
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.hd{
		overflow: hidden;
		.date{
			float: left;
			font-size: 1.2rem !important;
		}
		.note{
			float: right;
			font-size: 1.2rem !important
		}
	}
	
	h1{
		padding: 10px ;
		font-size: 2.8rem;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: .1em;
		text-align: justify;
	}
	
	h2{
		font-size: 2.4rem;
		margin-top: 50px;
		font-weight: 600;
		letter-spacing: .1em;
		border-top: 1px solid $line;
		border-bottom: 1px solid $line;
		padding: 8px 0;
		margin-bottom: 10px;
	}
	
	.wp-caption.alignnone{
		margin: 30px auto;
		max-width: 100%;
		img{
			width: 100% !important;
			height: auto !important;
		}
		.wp-caption-text{
			font-size: 1.2rem;
		}
	}
	
	p{
		font-size: 1.4rem;
		line-height: 2;
	}
	
	h4{
		background: #eee;
		padding: 10px;
		text-align: left;
		margin: 50px 0;
		margin-bottom: 0;
		font-size: 1.1rem;
	}
	
	h3.related_post_title{
		font-size: 1.8rem !important;
		font-weight: bold;
		margin: 10px 0;
	}
	
	
	ul.related_post{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			width: calc((100% - 20px) / 2);
			font-size: 1.2rem;
			a{
				display: block;
				margin-bottom: 10px;
				text-align: justify;
				line-height: 1.5;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
		}
	}
	
	.btn_blog.case{
		padding-bottom: 50*$px;
		border-bottom: 1px solid $green;
	}
	
	a.toHome{
		display: block;
		text-align: center;
		background: $green;
		color: #fff;
		line-height: 50px;
		margin:0 30*$px;
	}
	
}


/* !!------------------------------------ */
/* !! バナー */
.f-banner{
	position: fixed;
	bottom: 0;left: 0;
	z-index: 10;
	width: 100%;
	background: #ff6d05;
	height: 60px;
	text-align: center;
	a{
		font-size: 2.4rem;
		font-weight: bold;
		padding-left: 220px;
		color: #fff;
		width: auto;
		margin: 0 auto;
		display: inline-block;
		text-align: center;
		position: relative;
		line-height: 60px;
		br{
			display: none;
		}
		img{
			position: absolute;
			bottom: 0;left: 0;
		}
		&:hover{
			opacity: 1;
		}
	}
}






/* !!------------------------------------ */
/* !! フッター */

//!! フッターバナー
ul.footer-bnr{
	width: 100%;
	margin: 0;
	padding: $m;
}



footer {
	position: relative;
	padding-top: 65px;
	body.top & {
		padding-bottom:0px;
	}	
	&:before {
		content: "";
		width: 100%;
		height: 70px;
		display: block;
		@include bg-img(url(../img/bg_02.png),auto 66px, center center,repeat-x ); //$url,$size,$position,$repeat
		position: absolute;
		top: 0;
	}
	ul {
		margin: $m;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow: hidden;
		white-space: normal;
		li {
			font-size: 1.2rem;
			line-height: 1.45;
			display: block;
			vertical-align: top;
			margin-right: 1em;
			width: calc(50% - 10px);
			text-align: center;
			border: 1px solid $line;
			padding-right: 0;
			line-height: 1;
			padding: 10px;
			border-radius: 4px;
			//border-right: 1px solid #3e3a39;
			position: relative;
			&:after {
				content: none;
				width: 1px;
				height: 14px;
				display: block;
				background: #3e3a39;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
			a {
				display: block;
				font-size: 1.2rem;
				white-space: nowrap;
			}
			&:last-child {
				margin-right: 0;
				&:after {
					content: none;
				}
			}
		}
	}
	p {
		margin-right:0;
		overflow: hidden;
		line-height: 1;
		position: static;
		left: auto;
		padding: $m;
		padding-top: 1em !important;
		right: 0;
		.pc {
			display: inline-block !important;
		}
	}
}


/* !!------------------------------------ */
/* !! 相互リンク */

ul.footer-bnr{
	text-align: center;
	font-size: 0;
	margin-bottom: 0px;
	li{
		display:block;
		margin: 10px;
		img{
			max-width: 360px;
			height: auto;
			width: 100% !important;
		}
	}
	
}



/* !!------------------------------------ */
/* !! 下層ページ共通 */

.page-title
{
	line-height: 50px;
	text-align: center;
	color: #FFF;font-size: 20px;
	font-weight: 600;
	letter-spacing: .1em;
	background : -moz-linear-gradient(0% 50% 0deg,rgba(0, 89, 42, 1) 0%,rgba(0, 133, 69, 1) 54.49%,rgba(0, 89, 42, 1) 100%);
	background : -webkit-linear-gradient(0deg, rgba(0, 89, 42, 1) 0%, rgba(0, 133, 69, 1) 54.49%, rgba(0, 89, 42, 1) 100%);
	background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(0, 89, 42, 1) ),color-stop(0.5449,rgba(0, 133, 69, 1) ),color-stop(1,rgba(0, 89, 42, 1) ));
	background : -o-linear-gradient(0deg, rgba(0, 89, 42, 1) 0%, rgba(0, 133, 69, 1) 54.49%, rgba(0, 89, 42, 1) 100%);
	background : -ms-linear-gradient(0deg, rgba(0, 89, 42, 1) 0%, rgba(0, 133, 69, 1) 54.49%, rgba(0, 89, 42, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00592A', endColorstr='#00592A' ,GradientType=0)";
	background : linear-gradient(90deg, rgba(0, 89, 42, 1) 0%, rgba(0, 133, 69, 1) 54.49%, rgba(0, 89, 42, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00592A',endColorstr='#00592A' , GradientType=1);
}


/* !!------------------------------------ */
/* !! 加盟をご検討中の業者様 */


#for-partners{
	

	.mv{
		margin-bottom: 15*$px;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.box{
		margin-bottom: 30*$px;
		p{
			margin: 0 30px;
			font-size: 14px;
			line-height: 1.71;
			&.hd{
				background: #8fc131;
				line-height: 25px;
				border-radius: 17px;
				color: #FFF;
				text-align: center;
				margin-bottom: 10px;
				
			}
			&.note{
				@include note();
				i{
					color: #71b438;
				}
			}
		}
		.img{
			margin: 10*$px 0;
		}
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	ul.result{
		font-size: 12px;
		margin: 15*$px 30*$px;
		li{
			white-space: nowrap;
			display: flex;
			justify-content: space-between;
			line-height: 20px;
			position: relative;
			span{
				position: relative;
				z-index: 1;
				background: #fff;
				padding-right: 10px;
				& + span{
					padding-left: 10px;
					padding-right: 0;
				}
			}
			&:after{
				content: "";
				display: block;
				height: 1px;
				width: 100%;
				background: #ccc;
				z-index: 0;
				position: absolute;
				bottom: 50%;
				left: 0;
				
			}
		}
	}
	
	//!! - 【リクラシ】3つのポイント
	section.point{
		
		dl{
			background: #EAF2D3;
			padding: 20px;
			font-size: 14px;
			margin: 20px 30*$px;
			dt{
				font-size: 16px;
				color: $green2;
				margin-left: 66px;
				position: relative;
				font-weight: bold;
				line-height: 1.3;
				border-bottom: 3px dotted #fff;
				min-height: 50px;
				img{
					position: absolute;
					top: 0;left: -10px;
					@include transform(translateX(-100%));
				}
			}
			dd{
				@include pfix(.71em);
				padding-top: 15px;
			}
		}
		
	}//section.poin
	
	
	
	/* !! リクラシ3つのポイント */
	section.flow{
		padding-top: 30*$px;
		
		img{
			width: 100% !important;
			height: auto !important;
		}
		
		h3{
			position: relative;
			margin-top: 30*$px;
			margin-bottom: 15px;
			span{
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;z-index: 1;
				background: rgba($green2,0.8);
				color: #fff;
				padding: 10px 10px 10px 40px;
				font-size: 16px;
				line-height: 1.38;
				@include pfix(.38em);
				margin-bottom: 0;
				font-weight: bold;
				i{
					position: absolute;
					left: 8px;
					bottom: 10px;
					font-size: 45px;
					line-height: 1;
					font-family: YuGothic;
				}
			}
		}
		
		p{
			font-size: 14px;
			line-height: 1.71;
		}
		
		.wrap{
			margin: 30*$px;
			margin-bottom: 50*$px;
		}
	}//section.flow
}



/* !! - - -  */
/* !! ［加盟店さま用］　お問い合わせフォーム */
section#form-sec{
	position: relative;
	overflow: visible;
	background: #f3f7f2;
	padding: 30*$px;
	h2.nb{
		border-bottom: 0;
		margin-bottom: 30*$px;
		font-size: 16px;
		color: $green;
		span{
			margin: 0;
		}
	}

}
.partner_wrap {
	max-width: 1000px;
	width: 100%;
	padding: 0 ;
	position: relative;
	z-index: 1;
	overflow: hidden;
	
	img {
		position: absolute;
		top: 45px;
		right: 0;
	}
	ul {

		padding: 0 !important;
		&:before{
			content: none;
		}
		
		>li {
			padding: 0px;
			position: relative;
			z-index: 1;
			padding-left: 0;
			margin-bottom: 15px;
			&:last-of-type{
				border-bottom: 0;
			}
			>span{
				position: static;
				line-height: 20px;
				font-size: 14px;
				width: 100%;
				color: #000;
				font-weight: bold;
				display: block;
				margin-bottom: 10px;
				@include transform(translateY(0));
				i{
					background: $red;
					display: inline-block;
					float: right;
					color: #fff;
					height: 20px;
					width: 40px;
					border-radius: 4px;
					text-align: center;
					line-height: 20px;
					font-size: 1.2rem;
					font-weight: bold;
					margin-left: 0px;
				}
			}
			ol {
				display:block;
				li {
					//width: calc((100% - 30px)/5) !important;
					width: auto !important;
					white-space: nowrap;
					font-size: 1.6rem;
					display: inline-block;
					margin: 0 10px 0 0;
					padding: 5px;
					margin-bottom: 10px;
					input {
						width: auto;
						height: auto;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}
			&:nth-of-type(odd) {
				margin-right: 1px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.add {
				width: 100%;
				margin-right: 0;
				.wrap {
					width: 30% !important;
					position: relative;
					display: inline-block;
					input {
						padding-left: 35px;
					}
					&:before {
						content: "〒";
						font-size: 1.8rem;
						position: absolute;
						bottom: 10px;
						left: 15px;
					}
					+.wrap {
						width: calc(70% - 30px) !important;
						margin-left: 30px;
						input {
							padding-left: 15px;
						}
						&:before {
							content: none;
						}
					}
				}
			}
			&.inquiry {
				width: 100%;
			}
			input , select {
				width: 100%;
				height: 40px;
				font-size: 1.8rem;
				font-weight: 600;
				display: inline-block;
				vertical-align: top;
				padding: 10px 15px;
				border: 1px solid $line;
				&:focus {
					outline: none;
				}
			}
			textarea {
				display: block;
				width: 100% !important;
				height: 200px !important;
				font-size: 1.8rem;
				font-weight: 600;
				padding: 10px 15px;
				border: 1px solid rgba(34,24,21,0.5);
				resize: none;
				&:focus {
					outline: none;
				}
			}
			.mfp_err {
				display: none !important;
			}
			
			/* placeholder 色変更 */

			:placeholder-shown {
			    color: #CCC; }
			
			/* Google Chrome, Safari, Opera 15+, Android, iOS */
			::-webkit-input-placeholder {
			    color: #CCC; }
			
			/* Firefox 18- */
			:-moz-placeholder {
			    color: #CCC; opacity: 1; }
			
			/* Firefox 19+ */
			::-moz-placeholder {
			    color: #CCC; opacity: 1; }
			
			/* IE 10+ */
			:-ms-input-placeholder {
			    color: #CCC; }
		}
	}
	.bt_wrap {
		font-size: 0;
		margin: 0 auto;
		text-align: center;
		margin: 30px 0;
		margin-bottom: 0;
	}
	button {
		width:100%;
		max-width: 300px;
		width: 100%;
		height: 50px;
		font-size: 2rem;
		line-height: 50px;
		font-weight: 800;
		color: #fff;
		border: none;
		display: inline-block;
		margin: 0 ;
		margin-bottom: 20px;
		background: #BFBFBF;
		& + button{
			background: #E45A24;
			margin-bottom: 0;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	#mfp_phase_confirm_inner{
		padding: 20*$px;
	}
	#mfp_confirm_table {
		tr {
			display: flex;
			flex-wrap: wrap;
			th, td {
				line-height: 30px;
				padding-bottom: 10px;
			}
		}
	}
}


//!! - 確認画面
#mfp_phase_confirm{
	position: relative;
	z-index: 1;
	padding: 20*$px !important;
	th,td{
		padding: 20*$px !important;
	}
	
	.mfp_buttons{
		margin: 50px 0;
		button{
		width:100%;
			max-width: 300px;
			height: 50px;
			font-size: 2rem;
			line-height: 50px;
			font-weight: 800;
			color: #fff;
			border: none;
			display: inline-block;
			margin: 0;
			margin-bottom: 20px;
			background: #656666;
			&#mfp_button_send{
				background: $green;
			}
		}
	}
}


/* !!------------------------------------ */
/* !! お問い合わせ完了 */
main.thanks{
	overflow: hidden;
	padding: 0 20*$px;
	
	nav{
		display: block;
		background: #EAE9E6;
		padding: 30px;
		
		position: relative;
		@include fitW(20);
		&:before{
			content: "" !important;
		}
		&:after{
			content: "";
			display: block;
			width: 50px;
			height: 50px;
			position: absolute;
			bottom: 0;
			left: 50%;
			background: inherit;
			@include transform(translate(-50%,50%) rotate(45deg));
		}
		ul{
			font-size: 1.6rem;
			z-index: 2;
			//color: #fff;
			//font-weight: 600;
			//max-width: 1000px;
			//margin: 0 auto;
			display: block;
			//flex-wrap: wrap;
			//justify-content: space-between;
			li{
				height: 50px;
				background: $green3;
				max-width: 100%;
				text-align: left;
				width: 100%;
				margin-bottom: 10px;
				&:nth-last-of-type(n + 3){
					margin-bottom: 10px;
				}
				a{
					font-size: 16*$px;
					line-height: 50px;
					text-align: center;
					position: relative;
					display: block;
					color: #fff;
					&:after{
						@include anchor(10px,#fff,2px);
						right: 20px;
						top: 45%;
					}
				}
			}
		}
	}
	
	>.inner{
		padding: 20*$px;
	}
	
	h2{
		font-size: 18*$px;
		white-space: nowrap;
		margin-top: 50*$px !important;
	}
	
	h3{
		font-size: 1.8rem;
		font-weight: 800;
		margin: 30px auto 10px auto;
		max-width: 1000px;
	}


	.table-wrap{
		margin-bottom: 10px;
	}


	table{
		max-width: 1000px;
		margin: 0 auto;
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
		font-size: 1.3rem;
		&,th,td{
			border: 1px solid $line;
		}
		th,td{
			padding: 10px 5px;
			text-align: center;
		}
		th{
			background: #EFEEED;
		}
		
	}
	
	>p{
		font-size: 1.6rem;
		max-width: 1000px;
		margin: 0px auto;
	}
	
	p.note{
		font-size: 1.6rem;
		max-width: 1000px;
		margin: 0px auto;
	}


	p.wrap {
		font-size: 1.6em;
		width: 100% !important;
		height: auto !important;
		line-height: 1;
		border: 1px solid rgba(0, 0, 0, 0.3);
		display: block;
		padding: 30px;
		margin: 20px 0;
		text-align: center;
		>span{
			font-size: 2rem;
			display: inline-block;
			margin-left:0px;
			padding-right:15px;
			line-height: 56px;
			vertical-align: top;
		}
		a{
			vertical-align: top;
			border-left: 1px dotted rgba(0, 0, 0, 0.3);
			position: static !important;
			display: inline-block;
			padding-left: 15px;
			box-sizing: border-box;
			width: 275px !important;
			img{
				position: static;
				display: inline-block;
				//padding-left: 15px;
			}
			span{
				display: inline-block !important;
				
			}
		}
		
		
	}
	
	p.callBox{
		max-width: 1000px;
		margin: 50px auto;

		a{
			display: block;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		& + p{
			margin: 30px auto;
			max-width: 1000px;
			font-size: 1.6rem;
			text-align: center;
			font-weight: 600;
		}
	}

	.cashback_wrap {
		max-width: 1000px;
		margin: 0 auto;
		h2 {
			max-width: 1000px;
			
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		img + p {
			font-size: 1.6rem;
			margin-top: 20px;
		}
		h3 {
			font-size: 2.4rem;
			line-height: 1.2;
			font-weight: bold;
			border-bottom: 4px solid #3e3a39;
			padding-bottom: 10px;
			margin: 30px 0 20px 0;

			text-align: justify;
		}
		table {
			width: 100%;
			border: 1px solid black;
			border-collapse: collapse;
			table-layout: fixed;
			margin-bottom: 30px;
			th {
				width: 30%;
				background: #eaf6fd;
				border-right: 1px solid black;
			}
			th, td {
				font-size: 1.6rem;
				font-weight: normal;
				padding: 15px;
				border-bottom: 1px solid black;
			}
			ul {
				li {
					font-size: 1.6rem;
					line-height: 1.6;
					text-align: justify;
					list-style: disc inside;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
			&.cash {
				td {
					text-align: center;
				}
				tr.start td {
					background: #eaf6fd;
				}
				+p {
					font-size: 1.6rem;
					margin-top: 20px;
				}
			}
		}
		p.wrap {
			font-size: 1.6em;
			width: 100% !important;
			height: auto !important;
			line-height: 1;
			border: 1px solid rgba(0, 0, 0, 0.3);
			display: block;
			padding: 30px;
			margin: 20px 0;
			text-align: center;
			>span{
				font-size: 2rem;
				display: inline-block;
				margin-left:0px;
				padding-right:15px;
				line-height: 56px;
				vertical-align: top;
			}
			a{
				vertical-align: top;
				border-left: 1px dotted rgba(0, 0, 0, 0.3);
				position: static !important;
				display: inline-block;
				padding-left: 15px;
				box-sizing: border-box;
				width: 275px !important;
				img{
					position: static;
					display: inline-block;
					//padding-left: 15px;
				}
				span{
					display: inline-block !important;
					
				}
			}
		}
	}

	
}//main.thanks


/* !! - - -  */
/* !! 加盟希望向け */
.thanks-partner{
	font-size: 1.6rem;
	line-height: 2;
	display: inline-block;
	border: 1px solid $line;
	padding: 30px;
	margin: 0px;
	margin-top: 30px;
	position: relative;
	left:0%;
	backface-visibility: hidden;
	@include transform(translateX(0%));
	margin-bottom: 50px;
}


/* !!------------------------------------ */
/* !! プライバシーポリシー */
#policy{
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
}

.policy-block{
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	padding: 30*$px;
	padding-bottom: 0;
	h3{
		font-size: 16px;
		margin-top: 30*$px;
		font-weight: 600;
		color: $green2;
		&:first-of-type{
			margin-top: 0;
		}
	}
	ul{
		font-size: 12px;
		line-height: 1.63;
		li{
			padding-left: 1em;
			@include note;
			&:before{
				content: "●";
				position: absolute;
				top: 0;left: 0;
			}
		}
	}
	p{
		font-size: 12px;
		line-height: 1.63;
		margin-top: .5em;
	}
}


/* !!------------------------------------ */
/* !! 利用規約 */

#terms-of-use{
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
}

.terms-of-use-block{
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	padding: 30*$px;
	padding-bottom: 0;
	line-height: 1.63;
	h3{
		font-size: 16px;
		margin-top: 30*$px;
		font-weight: 600;
		color: $green2;
		&:first-of-type{
			margin-top: 0;
		}
	}
	ul{
		font-size: 12px;
		line-height: 1.63;
		li{
			padding-left: 1em;
			@include note;
			&:before{
				content: "●";
				position: absolute;
				top: 0;left: 0;
			}
		}
	}
	ol{
		font-size: 12px;
		list-style: decimal;
		li{
			margin-left: 1.7em;
			list-style-position: outside;
			line-height: 1.63;
		}
	}
	p{
		font-size: 12px;
		line-height: 1.63;
		margin-top: .5em;
	}
}




/* !!------------------------------------ */
/* !! 運営会社 */
main#company{
	
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
	
	.mv{
		background: #eee;
		height: 200*$px;
	}
	
	.outline{
		margin: 0 30*$px;
		margin-top: 30*$px;
		dl{
			position: relative;
			border-top: 1px dotted $green;
			padding: 20*$px 0;
			font-size: 14px;
			font-size: 0;
			&:last-of-type{
				border-bottom: 1px dotted $green;
			}
			dt{
				display: inline-block;
				box-sizing: border-box;
				left: 0;
				height: 100%;
				vertical-align: top;
				width: 93px;
				font-size: 14px;
				border-right: 3px solid #e5e5e5;
			}
			dd{
				font-size: 14px;
				display: inline-block;
				width: calc(100% - 93px);
				vertical-align: top;
				padding-left: 20px;
				box-sizing: border-box;
			}
		}
	}
		
}



