$base:#947848;
$gold:#9F8248;
$pink:#E24567;
$brown:#b98f6d;;
$lite-pink:#FEF5F6;
$yellow:#FFE33F;
$text:#3e3a39;
$gray-lite:#e6e6e6;
$line:#BDBDBD;
$gray:#9FA0A0;
$green:#008545;
$green2:#008545;
$green3:#116736;
$red:#BA2528;
$m:1.56vw;

$px:0.27vw;

$ease1:  cubic-bezier(0.44, 0.07, 0.72, 1.19);

@mixin note($pl:1.4em){
	padding-left: $pl;
	position: relative;
	i{
		font-style: normal;
		position: absolute;
		top: 0;left: 0;
	}
}


@mixin grade1{
	background : -moz-linear-gradient(50% 100% 90deg,rgba(223, 230, 205, 1) 12.46%,rgba(237, 241, 227, 1) 54.98%,rgba(255, 255, 255, 1) 100%);
	background : -webkit-linear-gradient(90deg, rgba(223, 230, 205, 1) 12.46%, rgba(237, 241, 227, 1) 54.98%, rgba(255, 255, 255, 1) 100%);
	background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0.1246,rgba(223, 230, 205, 1) ),color-stop(0.5498,rgba(237, 241, 227, 1) ),color-stop(1,rgba(255, 255, 255, 1) ));
	background : -o-linear-gradient(90deg, rgba(223, 230, 205, 1) 12.46%, rgba(237, 241, 227, 1) 54.98%, rgba(255, 255, 255, 1) 100%);
	background : -ms-linear-gradient(90deg, rgba(223, 230, 205, 1) 12.46%, rgba(237, 241, 227, 1) 54.98%, rgba(255, 255, 255, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#DFE6CD', endColorstr='#FFFFFF' ,GradientType=0)";
	background : linear-gradient(0deg, rgba(223, 230, 205, 1) 12.46%, rgba(237, 241, 227, 1) 54.98%, rgba(255, 255, 255, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#DFE6CD' , GradientType=0);
}


@mixin grade2{

	background : -moz-linear-gradient(0% 50% 0deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%);
	background : -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(255, 255, 255, 1) ),color-stop(1,rgba(255, 255, 255, 0) ));
	background : -o-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	background : -ms-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#FFFFFF' ,GradientType=0)";
	background : linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#FFFFFF' , GradientType=1);
}

@mixin grade3{
  background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 255, 255, 1) 20.17%,rgba(255, 255, 255, 0) 100%);
  background : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 20.17%, rgba(255, 255, 255, 0) 100%);
  background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0.2017,rgba(255, 255, 255, 1) ),color-stop(1,rgba(255, 255, 255, 0) ));
  background : -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 20.17%, rgba(255, 255, 255, 0) 100%);
  background : -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 20.17%, rgba(255, 255, 255, 0) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#FFFFFF' ,GradientType=0)";
  background : linear-gradient(0deg, rgba(255, 255, 255, 1) 20.17%, rgba(255, 255, 255, 0) 100%);

  filter: alpha(opacity=90) progid:DXImageTransform.Microsoft.Alpha(opacity=90) progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#FFFFFF' , GradientType=0);
}



@mixin stripe1{
	background-image: linear-gradient(45deg, #fff6aa 25%, #ffffff 25%, #ffffff 50%, #fff6aa 50%, #fff6aa 75%, #ffffff 75%, #ffffff 100%);
	background-size: 11.31px 11.31px;
}

@mixin MB{
	font-family: "ゴシックMB101 B","Gothic MB101 Bold",sans-serif  !important;
	font-style: normal;
	font-weight: 600;
}

@mixin shingo{
	font-family: "新ゴ M","Shin Go Medium" !important;
}

//@import url(../fonts/icomoon/style.css);

@mixin minchou{
	font-family:"游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
}

@mixin gothic{
	font-family:"Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3","YuGothic","Meiryo","メイリオ",sans-serif !important;
}

@mixin pfix($gap){
	$em:$gap/2;
	margin-top: -$em;
	margin-bottom: -$em;
}

@mixin fitW($n:30){
	margin-left: -$n*$px;
	width: 100vw;
}

@mixin tap($scale:0.9){
	transition: 200ms $ease1;
	-webkit-tap-highlight-color:rgba(0,0,0,0) !important;
	cursor: pointer;
	a{
		-webkit-tap-highlight-color:rgba(0,0,0,0);
	}
	&:active{
		@include transform(scale($scale));
		will-change:transform;
	}
}


@mixin bg-img($url,$size:100% 100%,$position:center center,$repeat:no-repeat){
	background-image: $url;
	background-repeat: $repeat;
	-webkit-background-size:$size ;
	background-size:$size ;
	background-position:$position;
}

@mixin transition($arg){
	-webkit-transition:$arg;
	-moz-transition:$arg;
	-ms-transition:$arg;
	-o-transition:$arg;
	//filter:$arg;
}


@mixin filter($arg){
	-webkit-filter:$arg;
	-moz-filter:$arg;
	-ms-filter:$arg;
	-o-filter:$arg;
	filter:$arg;
}

@mixin transform($arg){
	-webkit-transform:$arg;
	-moz-transform:$arg;
	-ms-transform:$arg;
	-o-transform:$arg;
	transform:$arg;
}

@mixin brend($color){
	background-blend-mode: exclusion;
	background-color: $color ;
}

@mixin webfont($fw){
		//font-family: 'Ropa Sans', sans-serif;
		font-family: 'Roboto Slab', serif !important;
		font-weight: $fw;
	}

@mixin header-icon-shadow(){
	-webkit-filter:drop-shadow(rgba(0,0,0,0.8) 1px 1px 2px);
}

@mixin a-cover{
	position: absolute;
	display: block;
	z-index: 1;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
}

@mixin centering{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin side-kakko($width:10px){ 
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid $text;
		border-right-width: 0;
		height: 100%;
		width: $width;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;right: 0;
		border: 1px solid #666;
		border-left-width: 0;
		height: 100%;
		width: $width;
	}
}

@mixin bar($color){
	content: "";
	width: 50px;
	height: 5px;
	background:$color ;
	display:block;
	margin: 0 auto;
}


@mixin anchor($size:10px,$color:#000,$width:1px){
	display: block;
	position: absolute;
	right: 15px;top: 50%;
	content: "";
	width: $size;height: $size;
	border: $width solid  $color;
	border-width: 0 $width $width 0;
	@include transform(rotate(45deg) translateY(-50%));
	transform-origin: center center;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  } 
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}


/* !!------------------------------------ */
/* !! クリップ */
@mixin clip-path($d){
		clip-path: $d;
}
