@media screen and (min-width: 767px) {


/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! PC */
/* !!------------------------------------ */
/* !!------------------------------------ */


/* !!------------------------------------ */
/* !! 共通 */

$px:1px;

html {
	font-size: 10px;
	line-height: 1.6; 
}

body{
	margin: 0;
	padding: 0;
	min-width: 1100px;
	color: $text;
	@include gothic;
	font-feature-settings: 'palt' 1;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	min-width: 1200px;
	padding-top: 70px;
	&.sp{
		display: block !important;
	}
}

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

main,article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section , address {
	display: block;
	overflow: visible;
}

a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
	
	&:hover {
		opacity: 0.7;
	}
	
}

ul , ol {
	list-style: none;
	font-size: 0;
	line-height: 1;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure , form , button {
	margin: 0;
	padding: 0;
	letter-spacing: 0;
	font-weight: normal;
}

i, cite, em, var, address, dfn {
	font-style: normal;	
}

img {
	vertical-align:bottom;
	backface-visibility: hidden;
	outline:none;
	border: 0;
	-ms-interpolation-mode: bicubic;
}

p {
	margin: 0;
	overflow: hidden;
}

form {
	margin: 0;
}

::selection {
	color: #006934;
	background: #cadb4d;
}

::-moz-selection {
	color: #006934;
	background: #cadb4d;
}

#container {
	width: 100%;
	margin: 0 auto;
	position:relative;
}

.inner {
	margin:0 auto;
	width: 100%;
	max-width: 1200px;
	height: 100%;
	position:relative;
	overflow: hidden;
}

.sp {
	display: none !important;
}

.pc {
	display: block !important;
}

ul.disk{
	list-style: disc;
	padding-left: 1.4em;
}


.idx{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}

.btn{
	width: 240px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 18px;
	letter-spacing: .1em;
	color: #fff;
	border-radius: 25px;
	display: block;
	background: $green;
	position: relative;
	margin: 0 auto;
	
	&:after{
		@include anchor(10px,#fff);
		border-width: 1px 1px 0 0;
		right: 25px;
	}
}

/* !!------------------------------------ */
/* !! ヘッダー */
#site-header{
	height: 70px;
	position: relative;
	position: fixed;
	z-index: 10;
	top: 0;left: 0;
	width: 100%;
	background: #fff;
	.inner{
		width: 100%;
		max-width: 10000px;
	}
	#logo{
		position: absolute;
		left: 30px;top: 50%;
		@include transform(translateY(-50%));
		img{
			height: 30px;
			width: auto;
		}
	}
	
	
	
	#btn-spmenu{
		display: none;
		//position: absolute;
		//top: 10px;right: 15px;
		//z-index: 1;
		//cursor: pointer;
	}
}


a.more{	
	display: block;
	text-align: center;
	line-height: 50px;
	font-size: 25px;
	position: relative;
	&:before{
		content: "もっと見る..."
	}

}

/* !!------------------------------------ */
/* !! グローバルナビ */

nav#gnav{
	
	width: 100vw;
	min-width: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
	background: none;
	transition: 0;
	opacity: 1;
	pointer-events: none;
	@include transform(translateY(0));
	
	//body.menu_on &{
	//	@include transform(translateY(0));
	//	opacity: 1;
	//	pointer-events: auto;
	//}
	
	ul{
		//padding:0;
		pointer-events: auto;
		display: list-item;
		width: 1000px;
		margin: 0 auto;
		top: 0;
		margin-top: 0;
		@include transform(translateY(0));
		text-align: center;
		li{
			display: inline-block;
			width: auto;
			height: auto;padding-left: 0px;
			width: auto;
			text-align: center;
			&:last-of-type a{
				border-right: none;
			}
			a{
				display: block;
				//background: #eee;
				line-height: 70px;
				padding: 0 15px;
				font-size: 14px;
				font-weight: 600;
				//margin: 0;
				//padding: 0;
				//background: #eee;
				//display: block;
				//color: $green;
				//position: relative;
			}
			
		}
	}
}


.btn-partner {
	background: #fff;
	height: 70px;width: 150px;
	position: absolute;
	bottom:auto;left: auto;
	top: 0;right: 10px;
	pointer-events: auto;
	&:before{
		content: none;
		//width: 100%;
		//height: 50px;
		//display: block;
		//position: absolute;
		//top: -46px;
		//left: 0;
		//@include bg-img(url(../img/spmenu-bg@2x.png),auto 100%,center bottom,repeat-x); //$url,$size,$position,$repeat
	}
	&:after{
		content: none;
		width: 100%;
		//height: 100%;
		//background: $green;
		//z-index: 1;
		//position: relative;
		//display: block;
	}
	a{
		width: 100%;
		height: 40px;
		border-radius: 20px;
		@include centering;
		text-align: center;
		color: #fff;
		line-height: 40px;
		font-size: 16px;
		font-weight: 600;
		display: block;
		border: 0;
		background: $green;
		z-index: 2;
	}
}

.btn.close{
	display: none;
	//@include tap;
	////transition: 300ms;
	//top: 25px;
	//right: 25px;
	//width: 50px;
	//height: 50px;
	//position: absolute;
	//top: 10px;right: 15px;
	//cursor: pointer;
	//@include bg-img(url(../img/btn-close@2x.png),30px 30px,center center); //$url,$size,$position,$repeat
}


img{
	backface-visibility: hidden;
}

/* !!------------------------------------ */
/* !! トップページ */

h2.dot{
	width: 750px;
	font-size: 36px;
	line-height: 95px;
	//text-align: center;
	//position: relative;
	//color: $green2;
	margin: 0 auto;
	font-weight: 700;
	margin-bottom: 10px;
	//@include MB;
	&:before{
		content: "";
		display: block;
		height: 4px;
		@include bg-img(url(../../img/top/bg-dot1@3x.png),auto 4px); //$url,$size,$position,$repeat
	}
	&:after{
		content: "";
		display: block;
		height: 4px;
		@include bg-img(url(../../img/top/bg-dot1@3x.png),auto 4px); //$url,$size,$position,$repeat
	}
}

.dv{
	font-size: 36px;
	font-weight: 700;
	color: $green;
	text-align: center;
	line-height: 1;
	margin: 100px 0;
	letter-spacing: .08em;
	&:after{
		content: "";
		width: 50px;
		height: 3px;
		background: $green;
		display: block;
		margin: 0 auto;
		margin-top: 15px;
	}
}

h2.dv{
	margin-bottom: 50px;
}


//!! コンタクトボックス
.contact-box{
	width: 100vw;
	padding: 0;
	padding-top: 50px;
	overflow: visible;
	position: relative;
	&:before{
		content: "";
		position: absolute;
		width: 100%;height: 100%;
		@include bg-img(url(../img/cbox-bg.png)); //$url,$size,$position,$repeat
		display: block;
		z-index: 0;
		top: 0;
		left: 0;
		opacity: 1;
		transition: 1000ms;
		pointer-events: none;
	}
	&.inv:before{
		opacity: 0;
	}
	a{
		margin-top: 10px;
		display: block;
	}
	img{
		//width: 100% !important;
		height: auto !important;
	}
	
	img.btn-form{
		width: 600px !important;
		margin: 0 auto !important;
		display: block;
		cursor: pointer;
	}
	a.call{
		display: block;
		width: 600px !important;
		overflow: visible;
		margin: 0 auto;
		img{
			width: 644px !important;
			height: auto;
		}
	}
}



/* !! - - -  */
/* !! MV */
#MV{
	width: 100%;
	height: calc(100vh - 70px);
	position: relative;
	overflow: hidden;
	max-height: 760px;
	.pc-wrap{
		width: 800px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		@include transform(translate(-50%,-50%));
	}
	h1{
		position: relative;
		width: 600px;
		top: 0;
		left: 0;
		transform: none !important;
		margin: 0 auto;
		z-index: 1;
		img{
			width: 100%;
			height: auto;
		}
	}
	.bnr-form{
		position: relative;
		width: 610px !important;
		margin: 0 auto;
		bottom: 0;
		left: 0;
		margin-top: 30px;
		@include transform(translateX(0));
		z-index: 1;
		img{
			width: 100% !important;
			height: auto !important;
		}
		.btn-form{
			width: 600px !important;
			margin: 0 auto;
			margin-top: 22px;
			display: block;
		}
	}
	.cell{
		height: calc(100vh - 70px);
		width: 100vw;
		max-height: 760px;

		&._1{
			@include bg-img(url(../../img/top/SLD1_pc@2x.jpg),cover); //$url,$size,$position,$repeat
		}
		&._2{
			@include bg-img(url(../../img/top/SLD2_pc@2x.jpg),cover); //$url,$size,$position,$repeat
		}
		&._3{
			@include bg-img(url(../../img/top/SLD3_pc@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}
/*
	#slide{
		font-size: 0;
		white-space: nowrap;
		text-align: center;
		opacity: 1;
		transition: 500ms opacity;
		transition-delay: 1000ms;
		&.set{
			opacity: 0;
		}
		.cell{
			display: inline-block;
		}
		
	}
*/
	
}

.bnr-call{
	width: 100%;
	@include bg-img(url(../img/cbox-bg.png)); //$url,$size,$position,$repeat
		padding-top: 50px;
	a{
		margin: 0 auto;
		width: 600px;
		position: relative;
		z-index: 1;
		img{
			width: 644px !important;
			height: auto !important;
		}
	}
}

/* !! - - -  */
/* !! 比較するから価格も安い！ */
#estimate{
	
	h2{
		@include gothic;
		font-size: 40px;
		//font-weight: 900;
		//text-align: center;
		//color: $red;
		line-height: 1;
		font-weight: 800;
		margin: 50px 0;
	}
	
	.estimate-slide{
		width: 1000px;
		margin: 0 auto;
		white-space: nowrap;
		position: relative;
		&:before{
			content: "";
			height: 100%;width: 100px;
			display: block;
			position: absolute;
			z-index: 1; 
			top: 0;left: 0;
			@include grade2;
		}
		&:after{
			content: "";
			height: 100%;width: 100px;
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;right: 0;
			@include grade2;
			transform: rotate(180deg);
		}
		.cell{
			width:360px ;
			display: inline-block;
			vertical-align: top;
			//background: #eee;
			padding: 0 25*$px;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			z-index: 1;
			justify-content: space-between;
			&:focus{
				outline:none;
			}
			&:before{
				content: "";
				width: 32*$px;
				margin-left: -15*$px;
				height: 67*$px;
				position: absolute;
				top: 38*$px;
				left: 50%;
				z-index: 2;
				@include bg-img(url(../../img/top/dec-tri@3x.png)); //$url,$size,$position,$repeat
			}
		}
		.img{
			width: calc((100% - 15px) / 2 );
			height: 130*$px;
			margin-top: 0;
			background: #fff;
			position: relative;
			overflow: hidden;
			background: #efefef;
			&:before{
				content: "BEFORE";
				position: absolute;
				left: -11px;
				padding-left: 12px;
				font-size: 10px;
				top: 0px;
				background: #666666;
				width: 106px;
				text-align: left;
				transform-origin: center center;
				color: #fff;
				line-height: 15px;
				@include transform(rotate(-30deg));
			}
		}
		.after{
			&:before{
				content: "AFTER";
				background: $red;
			}
		}
		.price{
			width: calc((100% - 20px) / 3);
			border: 1px solid #BFBFBF;
			border-radius: 4px;
			overflow: hidden;
			font-size: 28px;
			text-align: center;
			@include MB;
			background: #fff;
			margin-top: 10px;
			letter-spacing: -.03em;
			position: relative;
			&.lowest{
				color: $red;
			}
			&:after{
				content: "万円";
				font-size: 12px;
				padding-left: 5px;
				color: #000;
			}
			i{
				background: #BFBFBF;
				font-size: 13px;
				line-height: 25px;
				text-align: center;
				color: #000;
				letter-spacing: 0;
				display: block;
			}
		}
		.result{
			width: 100%;
			line-height: 45px;
			font-size: 18px;
			white-space: nowrap;
			text-align: center;
			@include MB;
			color: #fff;
			border-radius: 4px;
			margin-top: 10px;
			@include bg-img(url(../../img/top/estimate-bg@3x.png),auto 100%); //$url,$size,$position,$repeat
			i{
				font-size: 38*$px;
				line-height: 45*$px;
				vertical-align: top;
				
				margin-top: 10px;
				color: #fff;
				&:after{
					content: "万円";
					font-size: 15*$px;
				}
			}
		}
	}
	
}//#estimate

/* !! - - -  */
/* !! リクラシとは */


#about{
	display: block;
	padding: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	@include grade1;
	.lead{
		text-align: center;
		font-size: 30px;
		line-height: 1.6;
		position: relative;
		z-index: 1;
		//color: $green2;
		//font-weight: 600;
	}
	.idx{
		width: 1000px;
		padding: 50px 0;
		margin: 0 auto;
	}
	.img{
		width: 460px;
		margin: 0;
		margin-top:0;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	.wrap{
		position: relative;
		display: flex;
		align-items: center;
	}
	p.content{
		width: 497px;
		text-align: justify;
		padding:0 ;
		position: relative;
		line-height: 1.7;
		//@include grade1;
		font-size:16px;
		margin: 0;
		background: none;
	}
}



#support{
	display: block;
	padding: 50*$px 0;
	width: 100%;
	margin: 0 auto;
	overflow: visible;
	background: none;
	h2{
		text-align: center;
		font-weight: bold;
		color: $green;
		line-height: 1;
		font-size: 32px;
		font-weight: 800;
		margin-bottom: 20*$px;
		strong{
			font-size: 50px;
			display: block;
			margin: 20px 0;
			font-weight: 700;
		}
	}
	img{
		width: auto !important;
		height: auto !important;
	}
	img.btn-form{
		width: 600px !important;
		margin: 0 auto !important;
		display: block;
	}

	.img{
		width: 894px;
		margin: 50px auto;
		
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	p{
		font-size: 24px;
		font-weight: bold;
		line-height: 1.67;
		text-align: center;
		color: $green2;
		padding:0;
		background: none !important;
		margin-bottom: 50px;
	}
	.inner{
		margin: 0 auto;
		overflow: visible;
		width: 800px;
		position: relative;
	.img{
		width: 800px;
		position: relative;
		margin-bottom: 30px;
	}
		.p1{
			text-align: justify;
			width: 500px;
			font-size: 18px;
			color: #000;
			padding: 20px 30px;
			margin: 0 !important;
			position: relative;
			z-index: 1;
			font-weight: normal;
			line-height: 1.78;
			background: rgba(244, 255, 244, 0.7) !important;
			position: absolute;
			top: 50%;right: -50px;
			@include transform(translateY(-50%));
			&.sp{
				display: none;
			}
		}
		.p2{
			width: 100%;
			background: rgba(244, 255, 244, 0.7) !important;

			position: relative;
			text-align: justify;
			color: #000;
			font-size: 18px;
			font-weight: normal;
			padding-right: 0;
			padding: 40px;
			padding-left: 160px;
			overflow: visible;
			strong{
				display: block;
				font-size: 24px;
				font-weight: 800;
				color: $green2;
				margin-bottom: 10px;
				white-space: nowrap;
			}
			img{
				position: absolute;
				bottom: 0;right: auto;
				left: 0;
				width:153*$px !important;
				height: auto !important;
				margin-right: -20*$px;
				z-index: 2;
			}
		}
	}
}

/* !! - - -  */
/* !! コンタクト */
#shade{
	position: fixed;
	background: rgba(0, 0, 0, 0.58);
	width: 100vw;
	height: 130vh;
	top: 0;left: 0;z-index: 11;
	opacity: 0;
	pointer-events: none;
	body.form_on &{
		opacity: 1;
		pointer-events: auto;
	}
}

#contact-wrap{
	
	pointer-events: none;
	padding:0 $m;
	bottom: 0;left: 0;
	height: 1px;
	width: 100%;
	z-index: 10;
	position: absolute;
	top: 0;left: 0;
	z-index: 12;
	margin-top: 30px;
	overflow: visible;
	@include transform(translateY(20px));
	opacity: 0;
	pointer-events: none;
	transition: 300ms;
	body.form_on &{
		@include transform(translateY(0));
		opacity: 1;
		pointer-events: auto;
		.form{
			pointer-events: auto;
		}
	}
	.close{
		cursor: pointer;
		font-size: 0;
		position: absolute;
		top: 0;right: 0;
		z-index: 1;
		width: 36px;height: 36px;
		@include bg-img(url(../img/btn-close@2x.png),20px); //$url,$size,$position,$repeat
	}
	.form{
		
		pointer-events: none;
		position: absolute;
		width: 800px;
		box-sizing: border-box;
		top: 20px;left: 50%;
		margin-left: -400px;
		max-width: 900px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.33);
		padding: $m;
		margin-top: 0*$px;
		//position: relative;
		z-index: 10;
		background: #fff;
		.idx{
			display: block;
			//justify-content: space-between;
			.ph{
				margin-bottom: 1*$px;
				text-align: left;
				font-size: 16px;
			}
			>div{
				width: 100%;
				margin-bottom: 20*$px;
				>div{
					padding-left: 50px;
					height: 50px;
					position: relative;
					&.select:before{
						@include anchor(8px);
						margin-top: -2px;
						select{
							appearance: none !important;
						}
					}
					i{
						height: 50px;
						width: 50px;
						position: absolute;
						top: 0;left: 0;
						background: $green;
						display: block;
						font-size: 1.6rem;
						color: #FFF;
						line-height: 50px;
						text-align: center;
						font-weight: bold;
					}
					&:after{
						content: "";
						width:100%;
						height:100%;
						position: absolute;
						border: 1px solid $line;
						top: 0;left: 0;z-index: 1;
						pointer-events: none;
					}
				}
			}
			
		}
		.ctx1{
			padding-left: 0 !important;
			margin-top: -15px;
			font-size: 1.2rem;
			line-height: 1;
		}
		
		button.btn1{
			background: #116736;
			color: #fff;
			font-size: 2.4rem;
			font-weight: bold;
			border: 2px solid #fff !important;
			border-radius: 10px;
			box-shadow: none;
			max-width: 600px;
			width: 100%;
			line-height: 70px;
			display: block;
			box-shadow: 0 5px 8px rgba(0, 0, 0, 0.33);
			margin: 30px auto;
		}
		
		.tel{
			margin-bottom: 30px;
			a{
				display: block;
				img{
					width: 100%;
					max-width: 700px;
					height: auto;
					display: block;
					margin: 0 auto;
				}
			}
		}
		.supp{
			background: #EFEEED;
			padding: 10px 15px;
			position: relative;
			font-size: 1.2rem !important;
			font-size: 1.57em;
			text-align: justify;
			i{
				float: none;
				display: block;
				text-align: right;
			}
		}
	}
	
}




/* !! - - -  */
/* !! リフォーム */
#reform{
	width: 100%;
	margin: 0 ;
	margin-top: 70px;
	position: relative;
	&+.contact-box{
		position: relative;
		z-index: 2;
		margin-top: -76px;
		padding-top: 80px;
	}
	.img{
		height: 460px;
		img{
			width: auto !important;
			height: 100% !important;
			margin: 0 auto;
			display: block;
		}
	}
	img{
		width: auto !important;
		height: auto !important;
	}	
	.wrap{
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		width: 600px;
		margin-left: -100px;
		@include transform(translateY(-50%));
	}
	
	h2{
		text-align: justify;
		color: $green;
		font-size: 24px;
		line-height: 1.5;
		font-weight: bold;
		margin-top: 10*$px;
		margin-bottom: 15*$px;
		padding: 0;
		i{
			display: inline-block;
		}
	}
	
	p{
		margin-top: 50px;
		width: 100%;
		margin: 0 ;
		font-size: 18px;
		padding: 0;
	}
}



/* !! - - -  */
/* !! こんな方におすすめ */

#recommend{
	padding: 0;
	padding-top: 0*$px;	
	width: 800px;
	margin: 0 auto;
	ul{
		max-width: 800px;
		width: auto;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			text-align: center;
			width:246px;
			margin-bottom: 50px;
			.img{
				width: 100% !important;
				height: auto !important;
				img{
					width: 100% !important;
					height: auto !important;
					display: block;
				}
			}
			span{
				font-size: 16px;
				line-height: 1.5;
				white-space: nowrap;
				display: block;
				margin-top: 10*$px;
				text-align: left;
			}
		}
	}
	
}//#recommend




/* !! - - -  */
/* !! 見積り比較サイト【リクラシ】が選ばれている理由 */

section#why-rekurasi{
	padding-top: 0*$px;
	margin-bottom: 0;
	width: 1000px;
	margin: 0 auto;
	margin-bottom: 50px;
	.inner{
		padding: 0;
		ul{
			display:block;
			//justify-content: center;
			li{
				width: 1000px;
				height: 300px;
				margin-top: 30px;
				margin-right: 0;
				background: $green;
				position: relative;
				@include bg-img(url(../../img/top/p3_pc@2x.jpg),400px 300px,top left); //$url,$size,$position,$repeat
				&:nth-of-type(2){
					margin-left: 0;
					margin-top: 30px;
					@include bg-img(url(../../img/top/p4_pc@2x.jpg),400px 300px,top right); //$url,$size,$position,$repeat
					.inner{
						margin-left: 0;
						margin-right: 400px;
					}
					
				}
				&:nth-of-type(3){
					margin-left: 0;
					margin-top: 30px;
					@include bg-img(url(../../img/top/p5_pc@2x.jpg),400px 300px,top left); //$url,$size,$position,$repeat
				}
				.inner{
					height: auto !important;
					position: absolute;
					top: 50%;
					width: 600px;
					margin-left: 400px;
					padding:0 30px ;
					@include transform(translateY(-50%));
				}
				.hd{
					display: block;
					height: auto;
					width: 100%;
					background: #eee;
					position: relative;
					margin-bottom: 10px;
					margin-left: 0;
					overflow: visible;
					background: none !important;
					strong{
						padding-left: 0;
						background: none;
						font-size: 32px;
						color: #fff;
						position: relative;
						bottom: 0;
						width: 100%;
						left: 0;
						z-index: 1;
						padding: 0;
						line-height: 1.5;
						@include pfix(0);
						margin-bottom: 0;
					}
				}
				p{
					font-size: 18px;
					color: #fff;
					margin-left: 0;
					line-height: 1.4;
				}
				.note{
					@include note;
				}
			}
		}
	}
	
}





/* !! - - -  */
/* !! 施工実績 */

#reform-modelcase{
	padding-top: 50*$px;
	width: 1000px;
	margin: 0 auto;
	&.fold {
		ul.case-list{
			height: auto;
		}
	}
	
	a.more{
		display: none;
	}
	
	.btn{
		margin-top: 50px;
	}
	
	&:not(.fold){
		
		a.more:after{
			content: none;
		}
		a.more:before{
			content: "閉じる";
		}
	}
	ul.case-list{
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 50*$px;
		overflow: hidden;
		transition: 500ms;
		li{
			display: block;
			width: 235px;
			height: 235px;
			overflow: hidden;
			margin-bottom: 20px;
			img{
				width: 100% !important;
				height: auto !important;
			}
			a{
				display: block;
				white-space: nowrap;
				line-height: 32px;
				//text-align: center;
				//color: #FFF;
				font-size: 12px !important;
				//font-weight: bold;
			}
		}
	}
}






/* !! - - -  */
/* !! プロが語るリフォーム成功のポイント */


//!! - カテゴリ
#point{
	margin-top: 0;
	padding-top: 0;
	a.more{
		display: none;
	}
	
	a.btn{
		margin-top: 50px;
		margin-bottom: 50px;
	}
	
	ul.article-tag-list{
		background: #eee;
		border-bottom: 1px dotted $line;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		width: auto !important;
		padding-bottom: $m;
		margin: $m !important;
		font-size: 0;
		line-height: 1;
		li{
			flex:none;
			background: #fff;
			margin-right: auto;
			margin-bottom: 5px;
			height: 40px;
			display: block;
			width: calc((100% - 8px)/2) !important;
			a{
				display: block;
				line-height: 40px;
				font-size: 12px;
				padding-left: 30px;
				@include bg-img(url(../../img/icon-doc.png), 18px 21px, 10px 8px); //$url,$size,$position,$repeat
			}
		}
	}
}

#point:not(.fold){
	a.more:after{
		content: none;
	}
	a.more:before{
		content: "閉じる";
	}
}


//!! - 記事リスト
ul.article-list{
	width: 1000px;
	margin: 0 auto;
	margin-top: 50*$px;
	padding:0;
	display: grid;
	grid-auto-rows: 235px;
	grid-template-columns: repeat(auto-fill, 235px);
	grid-row-gap: 20px;
    grid-column-gap: 20px;
    //!! - - 通常
	.fold & {height: auto;}
	li{
		margin-top:0;
		margin-bottom:0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		text-align: right;
		position: relative;
		&:nth-of-type(10n + 1),
		&:nth-of-type(8n){
			grid-row: span 2;
			grid-column: span 2;
			.thumb{
				position: relative;
				z-index: 0;
				a{
					display: block;
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
				img{
					@include centering;
					width: auto !important;
					height: 100% !important;
				}
			}
			.title{
				font-size: 24px;
			}
			.desc{
				font-size: 16px;
			}
		}
		&:nth-of-type(5n + 1){
			.thumb{
				height: 100%;
			}
		}
		.thumb{
			position: relative;
			width: 100% !important;
			height: 100%;
			a{
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
			}
			.date{
				display: none;
				background: #3E3A39;
				color: #d3edfb;
				position: absolute;
				top: 0;left: 0;
				z-index: 1;
				font-size: 12px;
				line-height: 24px;
				padding:0px 10px;
			}
			img{
				width: auto!important;
				height: 100% !important;
				left: 50%;
				position: relative;
				@include transform(translateX(-50%));
			}
		}
		.txt{
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 10px 15px;
			padding-top: 100px;
			pointer-events: none;
			>*{
				z-index: 1;
				position: relative;
			}
			&:before{
				@include grade3;
				opacity : 0.8;
				content: "";
				display: block;
				height: 100%;
				width: 100%;
				z-index: 0;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		.title{
			font-size: 18px;
			text-align: justify;
			padding: 0;
			padding-bottom: 10px;
			@include pfix(.5em);
			line-height:1.4;
			font-weight: 600;
			letter-spacing: .04em;
			color: #000;
		}
		.desc{
			font-size: 12px;
			text-align: justify;
			padding: 0;
			line-height: 1.6;
			color: #000;
		}
		
		.btn-more{
			font-size: 14px !important;
			display: inline-block;
			border-bottom: 2px solid $text;
			padding-bottom: 4px;
			display: none;
			&:after{
				content: " ＞";
			}
		}
	}
	
	//!! - - IE
    html.ie & {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	    li{
			width: 313px;
			margin-bottom: 20px;
			img{
				position: relative !important;
				width: 100% !important;
				height: auto !important;
				top: 0 !important;left: 0 !important;
				@include transform(translate(0,0) !important);
			}
			&:nth-of-type(5n + 1){
				.thumb{
					height: auto !important;
				}
			}
			&:nth-of-type(10n + 1),
			&:nth-of-type(8n){
				grid-row: span 2;
				grid-column: span 2;
				.thumb{
					position: relative;
					z-index: 0;
					a{
						display: block;
						position: relative;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
					img{
						@include centering;
						width: 100% !important;
						height: auto !important;
					}
				}
				.title{
					font-size: 18px;
				}
				.desc{
					font-size: 12px;
				}
			}
			&:nth-of-type(5n + 1){
				.thumb{
					height: auto;
				}
			}
	    }
    }

}

/* !! - - -  */
/* !! ピックアップ加盟店 */
#partner{
	h3{
		background:rgba($green,0.6);
		line-height: 30px;
		text-align: center;
		font-size: 16px;
		letter-spacing: .1em;
		color: #FFF;
		font-weight: bold;
		border-radius: 15px;
		max-width: 1000px;
		margin: 0 auto;
		& + ul{
			margin-top: 15*$px !important;
			margin-bottom: 50px;
		}
	}
}


/* !! - - -  */
/* !! 「リクラシ」は第三者機関として、まずリフォーム */

#our-thought{
	background: $green;
	text-align: center;
	margin-top: 0 !important;
	.inner{
		padding: 50px 0;
		width: 1000px;
		overflow: visible;
		white-space: nowrap;
	}
	h2{
		background: none;
		color: #fff;
		font-size: 28px;
		font-weight: bold;
		margin-bottom: 26*$px;
		text-align: justify;
		line-height: 1.43;
		@include pfix(.43em);
		display: inline-block;
		vertical-align: top;
		br{
			display: block;
		}
	}
	.txt{
		width: 560px;
		display: inline-block;
		vertical-align: top;
		margin-left: 30px;
		white-space: normal;
	}
	p{
		strong{
			font-size: 18px;
			padding-bottom: 30px;
			line-height: 1.56;
			display: block;
			@include pfix(.56em);
			padding-bottom: 15px;
			i{
				display: block;
			}
		}
		text-align: justify;
		font-size:14px;
		color: #fff;
	}
	.ar{
		text-align: right;
	}
	
}


/* !!------------------------------------ */
/* !! 施工事例 */

/* !! - - -  */
/* !! index */

body.works{
	padding-bottom: 0px;
}//body.works

#works.index{
	padding-bottom: 50*$px;
	border-bottom: 0;
}

.notfound{
	text-align: center;
	font-size: 14px;
	border: 1px dashed #ccc;
	padding: 50px;
	margin: 50px auto;
	max-width: 800px;
}


#works-index{
	width: 1000px;
	font-size: 16px;
	margin: 30*$px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	li{
		margin-top: 30px;
		width: 315px !important;
		position: relative;
		&:hover{
			.img{
				opacity: .6;
			}
		}
		>a{
			@include a-cover;
		}
		.img{
			height: 200*$px;
			//background: #efefef;
		}
		.txt{
			margin-top: 10px;
			position: relative;
		}
		.cate{
			padding-left: 10px;
			padding-right: 30px;
			color: #fff;
			line-height: 30px;
			margin-left: 0*$px;
			width: auto;
			max-width: 250px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			font-weight: bold;
			@include bg-img(url(../img/cate-label-bg@3x.png),auto 100%,top right); 
		}
		.date{
			font-size: 12px;
			line-height: 30px;
			position: absolute;
			top: 0;right: 0;
		}
		.desc{
			margin-top: 14px ;
			line-height: 1.71;
			font-size: 14px;
			br{
				display: none;
			}
		}
	}
	
}//#works-index



//!! - 絞り込み
.category-filter-wrap{
	background: #E9F3ED;	
	text-align: center;
	position:relative;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 800px;
	margin: 0 auto;
	padding: 15px;
	//transform:translateY(calc(100% - 50px));
	border-top: 0 !important;
	border-radius: 8px;
	&.opened{
		.case-cate-list{
			@include transform(translate(-50%,-50%) !important);
		}
		
		i:before{
			@include transform(translateY(-50%) rotate(180deg));
		}
	}
	&:after{
		content: none;
	}
	i{
		display: block;
		text-align: center;
		width: 100%;
		pointer-events: none;
		font-size: 16px;
		font-weight: bold;
		//cursor: pointer;
		color: $green;
		line-height: 1;
		padding-left: 0;
		position: absolute;
		top: 0;left: 0;
		letter-spacing: .1em;
		@include transform(translateY(-50%));
		&:before{
			content:none;
		}
	}
	
	.case-cate-list{
		width: 100%;
		padding: 0;
		background:none;
		font-size: 12px;
		position: static;
		bottom: auto;
		border-radius:0;
		box-shadow: 0;
		@include transform(translate(0) !important);
		height: auto;
		overflow: auto;
		transition: 0;
		z-index: 1;
		justify-content: flex-start !important;
		li{
			&:last-of-type{
				a{
					border-bottom: 0;

				}
			}
			line-height: 24px;
			color: #000;
			margin:5px;
			flex-grow: 3;
			text-align: left;
			padding: 0;
			a{
				display: block;
				min-width: 114px;
				font-size: 12px;
				letter-spacing: .1em;
				background: #fff;
				text-align: center;
				padding: 0;
				padding: 0 15px;
				line-height:24px;
				border-radius: 4px;
				white-space: nowrap;
				border-bottom: none;
			}
		}
	}
}



/* !! - ページャー */
.wp-pagenavi{
	margin: 50px auto;
	text-align: center;
	padding: 0 30*$px;
	position: relative;
	width: 1000px;
	a,span{
		width: 36px;
		height: 36px;
		background: #e5e5e5;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		line-height: 36px;
		margin: 0 2px;
		&.current{
			background: $green;
			color: #fff;
		}
	}
	.pages{
		display: none;
	}
	.previouspostslink{
		position: absolute;
		top: 0;left: 0;
		font-size: 0;
		width: 36px;height: 36px;
		background: none;
		float: left;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
	}
	.nextpostslink{
		position: absolute;
		top: 0;right: 0;
		font-size: 0;
		float: right;
		background: none;
		width: 36px;height: 36px;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
		transform: rotate(180deg);
	}
	.last,.first{
		display: none;
	}
}

/* !! - - -  */
/* !! single */
#works.single{
	
	.dv{
		text-align: center;
	}
	h1{
		text-align: center;
		border:1px solid $line;
		letter-spacing: .1em;
		max-width: 1000px;
		margin: 0 auto;
		border-width: 1px 0;
		line-height: 1;
		padding: 20px 0;
		font-size: 20px;
		i{
			font-size: .66em;
			font-weight: normal;
		}
	}
	
	#before-after{
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
		margin-top: 50px;
		padding: 0;
		&:after{
			content: "▶︎";
			font-size: 32px;
			color: $green;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));			
		}
		>div{
			width: 450px;
			height: 450px;
			margin-top: 0 !important;
			position: relative;
			overflow: hidden;
			img{
				width: 100% !important;
				height: auto !important;
			}
			.label{
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;background: $gold;
				line-height: 32px;
				padding: 0 20px;
				font-weight: bold;
				font-size: 15px;
				color: #fff;
				letter-spacing: .1em;
			}
		}
		.before{
			@include transform(translate(0,0));
			opacity: 1;
			transition: 600ms ease-out;
			&.inv{
				@include transform(translate(-50px,0));
				opacity: 0;
			}
		}
		.after{
			@include transform(translate(0,0));
			opacity: 1;
			transition-delay: 2000ms;
			.photo{
				transition: 0;
			}
			transition: 600ms ease-out !important;
			&.inv{
				@include transform(translate(100px,0));
				opacity: 0;
			}
		}
	}
	
	.txt{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 30px;
		background: #f6f6f6;
		padding: 15px;
		border-radius: 8px;
		.cate{
			position: relative;
			padding-left: 110px;
			i{
				line-height: 30px;
				padding: 0 15px;
				background: #fff !important;
				border-radius: 15px;
				display: inline-block;
				margin-right: 10px;
				font-size: 12px;
			}
			span{
				position: absolute;
				left: 0;top: 0;
				line-height: 30px;
				width: 100px;
				background: $gold;
				color: #fff;
				text-align: center;
				font-size: 1.2rem;
				font-weight: bold;
			}
		}
		.desc{
			font-size: 16px;
			line-height: 1.66;
			padding-top: 15px;
			border-top: 1px dotted #fff;
		}
	}
	
	.prev-next{
		max-width: 1000px;
		margin: 20px auto;
		height: 30px;
		a{
			line-height: 30px;
			display: inline-block;
			color: $green;
			font-size: 14px;
			letter-spacing: .1em;
			//border-bottom: 1px solid $green !important;
		}
		.prev{
			&:before{
				content:"◀︎ ";
			}
		}
		.next{
			float: right;
			&:after{
				content:" ▶︎";
			}
		}
	}
	
}//#works.single



/* !!------------------------------------ */
/* !! 加盟店一覧 */
#partners.index{
	padding-bottom: 0*$px;
	border-bottom: 0;
}

#partners-index{
	width: 1000px;
	margin: 0 auto;
	margin-top: 0px;
	font-size: 16px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: visible;
	li{
		margin-top: 0;
		margin-bottom: 30px;
		width: 315px !important;
		.img{
			height: 200px;
			background: #efefef;
		}
		.txt{
			margin-top: 10px;
			position: relative;
			font-size: 16px;
			line-height: 1.6;
		}
		.name{
			font-weight: bold;
			letter-spacing: .14em;
			//font-size: 24px;
		}
		.tel{
			position: relative;
			padding-left: 100px;
			//font-size: 24px;
			i{
				position: absolute;
				top: 50%;left: 0;
				@include transform(translateY(-50%));
				width: 95px;
				text-align: center;
				background: #eee;
				font-size: 12px;
				line-height: 20px;
				color: #666;
			}
		}
	}
	
}//#partners-index

/* !!------------------------------------ */
/* !! アーカイブ */

main#topics{
	padding-bottom: 50*$px;
	border-bottom: 1px solid $green;
	.article-list{
		margin-top: 50px;
		li:nth-of-type(5n + 1){
			
			.thumb{
				@include fitW(0);
				height: 320*$px;
				overflow: hidden;
				img{
					@include centering;
				}
			}
		}
		
	}
}

/* !! - - -  */
/* !! カテゴリアーカイブ */
main.category-archive{
	
	h3{
		text-align: center;
		font-size: 2.0rem;
		@include transform(translateY(0));
		margin-bottom: 20px;
		span{
			display: inline-block;
			background: #EEECEB;
			padding: 0 20px;
		}
	}
	.notfound{
		display: block;
		background: rgba(255, 255, 255, 0.7);
		max-width: 1000px;
		
		padding:50px 20px !important;
		font-size: 1.6em;
		text-align: center;
		margin: 20*$px;
		border-radius: 10px;
		
	}

}





/* !!------------------------------------ */
/* !! お役立ち情報シングル */

main.single{
	
	margin-top: 50px;
	
	>section{
		padding:0*$px;
		padding-top: 10*$px;
		width: 800px;
		margin: 0 auto;
	}	
	
	.mv{
		width: 800px;
		margin: 0 auto;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.hd{
		overflow: hidden;
		.date{
			float: left;
			font-size: 1.2rem !important;
		}
		.note{
			float: right;
			font-size: 1.2rem !important
		}
	}
	
	h1{
		padding: 10px 0;
		font-size: 2.8rem;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: .1em;
		text-align: justify;
	}
	
	h2{
		font-size: 2.4rem;
		margin-top: 50px;
		font-weight: 600;
		letter-spacing: .1em;
		border-top: 1px solid $line;
		border-bottom: 1px solid $line;
		padding: 8px 0;
		margin-bottom: 10px;
	}
	
	.wp-caption.alignnone{
		margin: 30px auto;
		max-width: 100%;
		img{
			width: 100% !important;
			height: auto !important;
		}
		.wp-caption-text{
			font-size: 1.2rem;
		}
	}
	
	p{
		font-size: 1.6rem;
		line-height: 2;
	}
	
	h4{
		background: #eee;
		padding: 10px;
		text-align: left;
		margin: 50px 0;
		margin-bottom: 0;
		font-size: 1.5rem;
	}
	
	h3.related_post_title{
		font-size: 1.8rem !important;
		font-weight: bold;
		margin: 10px 0;
	}
	
	
	ul.related_post{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			width: calc((100% - 60px) / 4);
			font-size: 1.5rem;
			font-weight: bold;
			margin-bottom: 20px;
			a{
				display: block;
				margin-bottom: 10px;
				text-align: justify;
				line-height: 1.5;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
		}
	}
	
	.btn_blog.case{
		padding-bottom: 50*$px;
		border-bottom: 1px solid $green;
	}
	
	a.toHome{
		display: block;
		text-align: center;
		background: $green;
		color: #fff;
		font-size: 20px;
		width: 500px;
		line-height: 50px;
		margin:50px auto;
	}
	
}


/* !!------------------------------------ */
/* !! バナー */
.f-banner{
	position: fixed;
	bottom: 0;left: 0;
	z-index: 10;
	width: 100%;
	background: #ff6d05;
	height: 60px;
	text-align: center;
	a{
		font-size: 2.4rem;
		font-weight: bold;
		padding-left: 220px;
		color: #fff;
		width: auto;
		margin: 0 auto;
		display: inline-block;
		text-align: center;
		position: relative;
		line-height: 60px;
		br{
			display: none;
		}
		img{
			position: absolute;
			bottom: 0;left: 0;
		}
		&:hover{
			opacity: 1;
		}
	}
}






/* !!------------------------------------ */
/* !! フッター */

//!! フッターバナー
ul.footer-bnr{
	width: 100%;
	margin: 0;
	padding: $m;
}



footer {
	position: relative;
	padding-top: 65px;
	body.top & {
		padding-bottom:0px;
	}	
	&:before {
		content: "";
		width: 100%;
		height: 70px;
		display: block;
		@include bg-img(url(../img/bg_02.png),auto 66px, center center,repeat-x ); //$url,$size,$position,$repeat
		position: absolute;
		top: 0;
	}
	ul {
		margin: $m;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow: hidden;
		white-space: normal;
		li {
			font-size: 1.2rem;
			line-height: 1.45;
			display: block;
			vertical-align: top;
			margin-right: 1em;
			width: calc(50% - 10px);
			text-align: center;
			border: 1px solid $line;
			padding-right: 0;
			line-height: 1;
			padding: 10px;
			border-radius: 4px;
			//border-right: 1px solid #3e3a39;
			position: relative;
			&:after {
				content: none;
				width: 1px;
				height: 14px;
				display: block;
				background: #3e3a39;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
			a {
				display: block;
				font-size: 1.2rem;
				white-space: nowrap;
			}
			&:last-child {
				margin-right: 0;
				&:after {
					content: none;
				}
			}
		}
	}
	p {
		margin-right:0;
		overflow: hidden;
		line-height: 1;
		position: static;
		left: auto;
		padding: $m;
		padding-top: 1em !important;
		right: 0;
		.pc {
			display: inline-block !important;
		}
	}
}


/* !!------------------------------------ */
/* !! 相互リンク */

ul.footer-bnr{
	text-align: center;
	font-size: 0;
	margin-bottom: 0px;
	li{
		display:block;
		margin: 10px;
		img{
			max-width: 360px;
			height: auto;
			width: 100% !important;
		}
	}
	
}



/* !!------------------------------------ */
/* !! 下層ページ共通 */

.page-title
{
	line-height: 70px;
	text-align: center;
	color: #FFF;
	font-size: 36px;
	font-weight: 600;
}

#our-thought{
	margin-top: 100px;
}


/* !!------------------------------------ */
/* !! 加盟をご検討中の業者様 */


#for-partners{
	
	max-width: 1000px;
	margin: 50px auto;

	
	.box-grp{
		position: relative;
		max-width: 1000px;
		margin: 0 auto;
	}	
	
	
	.box{
		margin-bottom: 30*$px;
		width: 475px;
		p{
			margin: 0px;
			font-size: 16px;
			line-height: 1.71;
			&.hd{
				background: #8fc131;
				line-height: 40px;
				border-radius: 20px;
				color: #FFF;
				font-size: 18px;
				letter-spacing: .1em;
				font-weight: 700;
				text-align: center;
				margin-bottom: 20px;
				
			}
			&.note{
				@include note();
				i{
					color: #71b438;
				}
			}
		}
		.img{
			margin: 30*$px auto;
			width: 100%;
			&.result-img{
				height: auto;
				margin: 0;
				img{
					height: auto !important;
					width: 100% !important;
				}
			}
		}
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.box.result{
		position: absolute;
		top: 0;right: 0;
	}
	
	ul.result{
		font-size: 14px;
		margin: 15*$px 0 ;
		li{
			white-space: nowrap;
			display: flex;
			justify-content: space-between;
			line-height: 20px;
			position: relative;
			line-height: 1.6;
			span{
				position: relative;
				z-index: 1;
				background: #fff;
				padding-right: 10px;
				& + span{
					padding-left: 10px;
					padding-right: 0;
				}
			}
			&:after{
				content: "";
				display: block;
				height: 1px;
				width: 100%;
				background: #ccc;
				z-index: 0;
				position: absolute;
				bottom: 50%;
				left: 0;
				
			}
		}
	}
	
	h2{
		margin-top: 100px;
		margin-bottom: 30px;
	}
	
	//!! - 【リクラシ】3つのポイント
	section.point{
		max-width: 1000px;
		margin: 0 auto;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		dl{
			background: #EAF2D3;
			width: 315px;
			padding: 20px;
			font-size: 14px;
			margin: 0*$px;
			dt{
				font-size: 18px;
				position: relative;
				br{
					display: none;
				}
				color: $green2;
				margin-left: 60px;
				position: relative;
				font-weight: bold;
				line-height: 1.3;
				border-bottom: 3px dotted #fff;
				min-height: 50px;
				.s{
					font-size: 16px;
				}
				.img{
					position: absolute;
					top: 0;left: -10px;
					width: 80px;height: 50px;
					overflow: hidden;
					@include transform(translateX(-100%));
					img{
						position: absolute;
						display: block;
						transform: none;
						top: 0;right: 0;left: auto;
					}
					
				}
			}
			dd{
				font-size: 16px;
				@include pfix(.71em);
				padding-top: 15px;
			}
		}
		
	}//section.poin
	
	
	
	/* !! ご利用の流れ */
	section.flow{
		padding-top: 30*$px;
		
		.img{
			width: 640px !important;
			margin: 0 auto;
			height: auto !important;
			display: block;
		}
		
		.wrap{
			display: flex;
			flex-wrap: wrap;
			width: 800px ;
			margin: 0 auto !important;
			justify-content: space-between;
			>div{
				width: 380px;
			}
		}
		
		h3{
			position: relative;
			margin-top: 30*$px;
			margin-bottom: 15px;
			span{
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;z-index: 1;
				background: rgba($green2,0.8);
				color: #fff;
				padding: 0px;
				padding-left: 40px;
				height: 70px;
				font-size: 18px;
				line-height: 1.38;
				@include pfix(.38em);
				margin-bottom: 0;
				font-weight: bold;
				i{
					position: absolute;
					left: 8px;
					bottom: 12px;
					font-size: 45px;
					line-height: 1;
					font-family: YuGothic;
				}
				b{
					position: relative;
					top: 50%;
					display: block;
					@include transform(translateY(-50%));
				}
			}
		}
		
		p{
			font-size: 16px;
			line-height: 1.71;
		}
		
		.wrap{
			margin: 30*$px;
			margin-bottom: 50*$px;
		}
	}//section.flow
}



/* !! - - -  */
/* !! ［加盟店さま用］　お問い合わせフォーム */
section#form-sec{
	position: relative;
	overflow: visible;
	background: #f3f7f2;
	padding: 50*$px;
	h2.nb{
		width: 700px;
		margin: 0 auto;
		border-bottom: 0;
		margin-bottom: 30*$px;
		font-size: 24px;
		color: $green;
		span{
			margin: 0;
		}
	}

}
.partner_wrap {
	max-width: 700px;
	width: 100%;
	padding: 0 ;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin: 0 auto;
	img {
		position: absolute;
		top: 45px;
		right: 0;
	}
	ul {

		padding: 0 !important;
		&:before{
			content: none;
		}
		
		>li {
			padding: 0px;
			position: relative;
			z-index: 1;
			padding-left: 0;
			margin-bottom: 15px;
			&:last-of-type{
				border-bottom: 0;
			}
			>span{
				position: static;
				line-height: 20px;
				font-size: 14px;
				width: 100%;
				color: #000;
				font-weight: bold;
				display: block;
				margin-bottom: 10px;
				@include transform(translateY(0));
				i{
					background: $red;
					display: inline-block;
					float: right;
					color: #fff;
					height: 20px;
					width: 40px;
					border-radius: 4px;
					text-align: center;
					line-height: 20px;
					font-size: 1.2rem;
					font-weight: bold;
					margin-left: 0px;
				}
			}
			ol {
				display:block;
				li {
					//width: calc((100% - 30px)/5) !important;
					width: auto !important;
					white-space: nowrap;
					font-size: 1.6rem;
					display: inline-block;
					margin: 0 10px 0 0;
					padding: 5px;
					margin-bottom: 10px;
					input {
						width: auto;
						height: auto;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}
			&:nth-of-type(odd) {
				margin-right: 1px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.add {
				width: 100%;
				margin-right: 0;
				.wrap {
					width: 30% !important;
					position: relative;
					display: inline-block;
					input {
						padding-left: 35px;
					}
					&:before {
						content: "〒";
						font-size: 1.8rem;
						position: absolute;
						bottom: 10px;
						left: 15px;
					}
					+.wrap {
						width: calc(70% - 30px) !important;
						margin-left: 30px;
						input {
							padding-left: 15px;
						}
						&:before {
							content: none;
						}
					}
				}
			}
			&.inquiry {
				width: 100%;
			}
			input , select {
				width: 100%;
				height: 40px;
				font-size: 1.8rem;
				font-weight: 600;
				display: inline-block;
				vertical-align: top;
				padding: 10px 15px;
				border: 1px solid $line;
				&:focus {
					outline: none;
				}
			}
			textarea {
				display: block;
				width: 100% !important;
				height: 200px !important;
				font-size: 1.8rem;
				font-weight: 600;
				padding: 10px 15px;
				border: 1px solid rgba(34,24,21,0.5);
				resize: none;
				&:focus {
					outline: none;
				}
			}
			.mfp_err {
				display: none !important;
			}
			
			/* placeholder 色変更 */

			:placeholder-shown {
			    color: #CCC; }
			
			/* Google Chrome, Safari, Opera 15+, Android, iOS */
			::-webkit-input-placeholder {
			    color: #CCC; }
			
			/* Firefox 18- */
			:-moz-placeholder {
			    color: #CCC; opacity: 1; }
			
			/* Firefox 19+ */
			::-moz-placeholder {
			    color: #CCC; opacity: 1; }
			
			/* IE 10+ */
			:-ms-input-placeholder {
			    color: #CCC; }
		}
	}
	.bt_wrap {
		font-size: 0;
		margin: 0 auto;
		text-align: center;
		margin: 30px 0;
		margin-bottom: 0;
	}
	button {
		width:100%;
		max-width: 300px;
		width: 100%;
		height: 50px;
		font-size: 2rem;
		line-height: 50px;
		font-weight: 800;
		color: #fff;
		border: none;
		display: inline-block;
		margin: 0 10px;
		margin-bottom: 20px;
		background: #BFBFBF;
		& + button{
			background: #E45A24;
			margin-bottom: 0;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	#mfp_phase_confirm_inner{
		padding: 20*$px;
	}
	#mfp_confirm_table {
		tr {
			display: flex;
			flex-wrap: wrap;
			th, td {
				line-height: 30px;
				padding-bottom: 10px;
			}
		}
	}
}


//!! - 確認画面
#mfp_phase_confirm{
	position: relative;
	z-index: 1;
	padding: 20*$px !important;
	th,td{
		padding: 20*$px !important;
	}
	
	.mfp_buttons{
		margin: 50px 0;
		button{
		width:100%;
			max-width: 300px;
			height: 50px;
			font-size: 2rem;
			line-height: 50px;
			font-weight: 800;
			color: #fff;
			border: none;
			display: inline-block;
			margin: 0;
			margin-bottom: 20px;
			background: #656666;
			&#mfp_button_send{
				background: $green;
			}
		}
	}
}


/* !!------------------------------------ */
/* !! お問い合わせ完了 */
main.thanks{
	overflow: hidden;
	padding: 0 20*$px;
	width: 800px;
	margin: 0 auto;
	nav{
		display: block;
		margin-top: 50px;
		background: #EAE9E6;
		padding: 30px;
		
		position: relative;
		@include fitW(0);
		width: 100% !important;

		&:before{
			content: "" !important;
		}
		&:after{
			content: "";
			display: block;
			width: 50px;
			height: 50px;
			position: absolute;
			bottom: 0;
			left: 50%;
			background: inherit;
			@include transform(translate(-50%,50%) rotate(45deg));
		}
		ul{
			font-size: 1.6rem;
			z-index: 2;
			//color: #fff;
			//font-weight: 600;
			//max-width: 1000px;
			//margin: 0 auto;
			display: block;
			//flex-wrap: wrap;
			//justify-content: space-between;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			-webkit-flex-wrap: wrap;
			-moz-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			-o-flex-wrap: wrap;
			flex-wrap: wrap;
			justify-content: space-between;
			li{
				height: 50px;
				max-width: 100%;
				text-align: left;
				width: calc(50% - 10px);
				margin-bottom: 0px;
				&:nth-last-of-type(n + 3){
					margin-bottom: 20px;
				}
				a{
					font-size: 16*$px;
					line-height: 50px;
					text-align: center;
					position: relative;
					display: block;
					&:after{
						@include anchor(10px,#fff,2px);
						right: 20px;
						top: 45%;
					}
				}
			}
		}
	}
	
	>.inner{
		padding: 20*$px;
	}
	
	h2{
		font-size: 18*$px;
		white-space: nowrap;
		margin-top: 50*$px !important;
	}
	
	h3{
		font-size: 1.8rem;
		font-weight: 800;
		margin: 30px auto 10px auto;
		max-width: 1000px;
	}


	.table-wrap{
		margin-bottom: 10px;
	}


	table{
		max-width: 1000px;
		margin: 0 auto;
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
		font-size: 1.3rem;
		&,th,td{
			border: 1px solid $line;
		}
		th,td{
			padding: 10px 5px;
			text-align: center;
		}
		th{
			background: #EFEEED;
		}
		
	}
	
	>p{
		font-size: 1.6rem;
		max-width: 1000px;
		margin: 0px auto;
	}
	
	p.note{
		font-size: 1.6rem;
		max-width: 1000px;
		margin: 0px auto;
	}


	p.wrap {
		font-size: 1.6em;
		width: 100% !important;
		height: auto !important;
		line-height: 1;
		border: 1px solid rgba(0, 0, 0, 0.3);
		display: block;
		padding: 30px;
		margin: 20px 0;
		text-align: center;
		>span{
			font-size: 2rem;
			display: inline-block;
			margin-left:0px;
			padding-right:15px;
			line-height: 56px;
			vertical-align: top;
		}
		a{
			vertical-align: top;
			border-left: 1px dotted rgba(0, 0, 0, 0.3);
			position: static !important;
			display: inline-block;
			padding-left: 15px;
			box-sizing: border-box;
			width: 275px !important;
			img{
				position: static;
				display: inline-block;
				//padding-left: 15px;
			}
			span{
				display: inline-block !important;
				
			}
		}
		
		
	}
	
	p.callBox{
		max-width: 1000px;
		margin: 50px auto;
		a{
			display: block;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		& + p{
			margin: 30px auto;
			max-width: 1000px;
			font-size: 1.6rem;
			text-align: center;
			font-weight: 600;
		}
	}

	.cashback_wrap {
		max-width: 1000px;
		margin: 0 auto;
		h2 {
			max-width: 1000px;
			
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		img + p {
			font-size: 1.6rem;
			margin-top: 20px;
		}
		h3 {
			font-size: 2.4rem;
			line-height: 1.2;
			font-weight: bold;
			border-bottom: 4px solid #3e3a39;
			padding-bottom: 10px;
			margin: 30px 0 20px 0;

			text-align: justify;
		}
		table {
			width: 100%;
			border: 1px solid black;
			border-collapse: collapse;
			table-layout: fixed;
			margin-bottom: 30px;
			th {
				width: 30%;
				background: #eaf6fd;
				border-right: 1px solid black;
			}
			th, td {
				font-size: 1.6rem;
				font-weight: normal;
				padding: 15px;
				border-bottom: 1px solid black;
			}
			ul {
				li {
					font-size: 1.6rem;
					line-height: 1.6;
					text-align: justify;
					list-style: disc inside;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
			&.cash {
				td {
					text-align: center;
				}
				tr.start td {
					background: #eaf6fd;
				}
				+p {
					font-size: 1.6rem;
					margin-top: 20px;
				}
			}
		}
		p.wrap {
			font-size: 1.6em;
			width: 100% !important;
			height: auto !important;
			line-height: 1;
			border: 1px solid rgba(0, 0, 0, 0.3);
			display: block;
			padding: 30px;
			margin: 20px 0;
			text-align: center;
			>span{
				font-size: 2rem;
				display: inline-block;
				margin-left:0px;
				padding-right:15px;
				line-height: 56px;
				vertical-align: top;
			}
			a{
				vertical-align: top;
				border-left: 1px dotted rgba(0, 0, 0, 0.3);
				position: static !important;
				display: inline-block;
				padding-left: 15px;
				box-sizing: border-box;
				width: 275px !important;
				img{
					position: static;
					display: inline-block;
					//padding-left: 15px;
				}
				span{
					display: inline-block !important;
					
				}
			}
		}
	}

	
}//main.thanks


/* !! - - -  */
/* !! 加盟希望向け */
@media screen and (min-width: 768px) {
	main.thanks{
		min-height: calc(100vh - 380px);
		position: relative;
	}
}

.thanks-partner{
	font-size: 1.6rem;
	line-height: 2;
	display: inline-block;
	border: 1px solid $line;
	padding: 30px;
	left: 50%;
	position: absolute;
	top: 50%;
	white-space: nowrap;
	backface-visibility: hidden;
	@include transform(translate(-50%,-50%));
}


/* !!------------------------------------ */
/* !! プライバシーポリシー */
#policy{
	padding-bottom: 100*$px;
	border-bottom: 0;
}

.policy-block{
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	padding: 0$px;
	padding-bottom: 0;
	h3{
		font-size: 18px;
		margin-top: 30*$px;
		font-weight: 600;
		color: $green;
		&:first-of-type{
			margin-top: 0;
		}
	}
	ul{
		font-size: 16px;
		line-height: 1.63;
		li{
			padding-left: 1em;
			@include note;
			&:before{
				content: "●";
				position: absolute;
				top: 0;left: 0;
			}
		}
	}
	p{
		font-size: 16px;
		line-height: 1.63;
		margin-top: .5em;
	}
}


/* !!------------------------------------ */
/* !! 利用規約 */

#terms-of-use{
	padding-bottom: 100*$px;
	border-bottom:0;
}

.terms-of-use-block{
	max-width: 800;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	padding-bottom: 0;
	line-height: 1.63;
	h3{
		font-size: 18px;
		margin-top: 30*$px;
		font-weight: 600;
		color: $green2;
		&:first-of-type{
			margin-top: 0;
		}
	}
	ul{
		font-size: 16px;
		line-height: 1.63;
		li{
			padding-left: 1em;
			@include note;
			&:before{
				content: "●";
				position: absolute;
				top: 0;left: 0;
			}
		}
	}
	ol{
		font-size: 16px;
		list-style: decimal;
		li{
			margin-left: 1.7em;
			list-style-position: outside;
			line-height: 1.63;
		}
	}
	p{
		font-size: 16px;
		line-height: 1.63;
		margin-top: .5em;
	}
}




/* !!------------------------------------ */
/* !! 運営会社 */
main#company{
	
	padding-bottom: 0*$px;
	border-bottom: 0;
	
	width: 1000px;
	margin: 0 auto;

	.outline{
		margin:100px auto;
		width: 600px;
		dl{
			position: relative;
			border-top: 1px dotted $green;
			padding: 20*$px 0;
			font-size: 0;
			&:last-of-type{
				border-bottom: 1px dotted $green;
			}
			dt{
				display: inline-block;
				box-sizing: border-box;
				left: 0;
				height: 100%;
				vertical-align: top;
				width: 10em;
				font-size: 16px;
				border-right: 3px solid #e5e5e5;
			}
			dd{
				font-size: 16px;
				display: inline-block;
				width: calc(100% - 10em);
				vertical-align: top;
				padding-left: 20px;
				box-sizing: border-box;
			}
		}
	}
		
}

}//media

